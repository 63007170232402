import React from 'react'
import { Box, Button, FormControlLabel, FormGroup, Grid, Switch, Typography, Paper, IconButton } from '@mui/material'
import { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid';
import { LoadingButton } from '@mui/lab'
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { findOneTrainerSchedule, createTrainerSchedule } from '@/utils/TrainerSchedule';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from 'dayjs';
import Drawer from '@mui/material/Drawer';
import PageLoading from '@/components/PageLoading';
import CloseIcon from '@mui/icons-material/Close';

const EditSchedule = ({ date, setDate, reload }) => {

    const { token, user } = useSelector((state) => state.auth);
    
    const [TrainerSessions, setTrainerSessions] = useState([]);
    const [dayOff, setDayOff] = useState(0);
    const [formError, setFormError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    function sanitizeData(data) {
        const { is_off, working_hour } = data;
        setDayOff(is_off);
        const schedule = working_hour ? JSON.parse(working_hour) : [];
        setTrainerSessions(schedule);
    }

    function handleResetSchedule() {
        if (user?.trainer_information?.schedule) {
            setTrainerSessions(JSON.parse(user.trainer_information.schedule));
        }
    }

    async function handleGetData(user_id, date) {
        try {
            const response = await findOneTrainerSchedule(user_id, date);
            if (response && !response.error) {
                sanitizeData(response.data);
            } else {
                sanitizeData({
                    is_off: 0,
                    working_hour: null
                });
                handleResetSchedule();
            }
        } catch (error) {
            console.error('error', error);
        } finally {
            setIsLoaded(true);
        }
    }

    async function handleUpdateData(user_id, date) {
        try {
            setIsLoading(true);
            const response = await createTrainerSchedule(token, user_id, date, {
                is_off: dayOff,
                working_hour: dayOff == 1 ? null : JSON.stringify(TrainerSessions)
            });
            if (response && !response.error) {
                sanitizeData(response.data);
                setDate(false);
                reload();
            }
        } catch (error) {
            console.error('error', error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (user?.trainer_information?.id && date) {
            handleGetData(user.trainer_information.id, date);
        }
    }, [user, date])

    function handleAddSchedule() {
        setTrainerSessions((TrainerSessions) => ([
            ...TrainerSessions,
            {
                id: uuidv4(),
                start_time: "00:00:00",
                end_time: "00:00:00"
            }
        ]))
    }

    function handleUpdateSchedule(data) {
        const updateSessions = TrainerSessions.map((val) => val.id == data.id ? data : val);
        setTrainerSessions(updateSessions);
    }

    function handleDeleteSchedule(id) {
        const removeSessions = TrainerSessions.filter((_v) => _v.id !== id);
        setTrainerSessions(removeSessions);
    }

    function handleSubmit(e) {
        e.preventDefault();
        if (user?.trainer_information?.id) {
            handleUpdateData(user.trainer_information.id, date);
        }
    }

    return (
            <Drawer
                anchor="right"
                open={date ? true : false}
                onClose={() => setDate(false)}>
                <Box sx={{
                    paddingX: 3,
                    paddingY: 1,
                    borderBottomWidth: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    columnGap: 3
                }}>
                    <p class="font-bold uppercase">Edit schedule date: {date}</p>
                    <IconButton onClick={() => setDate(false)}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box sx={{
                    width: 680,
                    maxWidth: "80vw",
                    padding: 3
                }}>
                    {
                        isLoaded ? (
                            <form onSubmit={handleSubmit}>
                                <Box sx={{ mb: 3 }}>
                                    <Typography sx={{
                                        fontWeight: 600,
                                        textTransform: 'uppercase',
                                        mb: 2
                                    }}>
                                        Mark to day off
                                    </Typography>
                                    <FormGroup>
                                        <FormControlLabel
                                            value={dayOff}
                                            checked={dayOff}
                                            onChange={(e) => { setDayOff(e.target.checked) }}
                                            control={<Switch />} label="Enable day off" />
                                    </FormGroup>
                                </Box>
                                {
                                    dayOff == 0 ? (
                                        <Paper sx={{
                                            p: 3,
                                            mb: 3
                                        }}>
                                            <Box>
                                                <Typography sx={{
                                                    fontWeight: 600,
                                                    textTransform: 'uppercase',
                                                    mb: 2
                                                }}>
                                                    Your Schedule
                                                </Typography>
                                                {
                                                    Array.isArray(TrainerSessions) && TrainerSessions.length ?
                                                        TrainerSessions.map((val, index) => {
                                                            return (
                                                                <Box key={index}>
                                                                    <Grid container spacing={3} >
                                                                        <Grid item xs={6}>
                                                                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.start_time} required>
                                                                                <FormLabel>Start hour</FormLabel>
                                                                                <TimePicker
                                                                                    name="start_time"
                                                                                    value={val?.start_time ? dayjs(val.start_time, "HH:mm:ss") : dayjs("00:00:00", "HH:mm:ss")}
                                                                                    onChange={(value) => handleUpdateSchedule({ ...val, start_time: dayjs(value).format("HH:mm:ss") })}
                                                                                />
                                                                                {formError?.start_time ? <FormHelperText>{formError?.start_time[0]}</FormHelperText> : ""}
                                                                            </FormControl>
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.end_time} required>
                                                                                <FormLabel>End hour</FormLabel>
                                                                                <TimePicker
                                                                                    name="end_time"
                                                                                    value={val?.end_time ? dayjs(val.end_time, "HH:mm:ss") : dayjs("00:00:00", "HH:mm:ss")}
                                                                                    onChange={(value) => handleUpdateSchedule({ ...val, end_time: dayjs(value).format("HH:mm:ss") })}
                                                                                />
                                                                                {formError?.end_time ? <FormHelperText>{formError?.end_time[0]}</FormHelperText> : ""}
                                                                            </FormControl>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Box sx={{ textAlign: 'right' }}>
                                                                        <Button
                                                                            onClick={() => handleDeleteSchedule(val.id)}
                                                                            startIcon={<DeleteIcon />}>
                                                                            Delete schedule
                                                                        </Button>
                                                                    </Box>
                                                                </Box>
                                                            )
                                                        })
                                                        : ""
                                                }
                                                <Box sx={{ display: "flex", columnGap: 1 }}>
                                                    <Button
                                                        variant='contained'
                                                        onClick={() => handleResetSchedule()}>
                                                        Load default schedule
                                                    </Button>
                                                    <Button
                                                        variant='outlined'
                                                        onClick={() => handleAddSchedule()}>
                                                        Add schedule
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Paper>
                                    ) : ""
                                }
                                <LoadingButton
                                    variant='contained'
                                    type='submit'
                                    loading={isLoading}>
                                    Save
                                </LoadingButton>
                            </form>
                        ) : (
                            <PageLoading />
                        )
                    }

                </Box>
            </Drawer>
    )
}

export default EditSchedule
