import { Box, Typography, Button, Divider, Chip, Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { findOneSession } from '@/utils/Sessions'
import { useSelector } from 'react-redux'
import { useToasterContext } from '@/utils/ToasterContext'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { createUserSessions, findManyUserSubscriptions } from '@/utils/UserInfomations'

import validate from 'validate.js'
import PageLoading from '@/components/PageLoading'
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import PeopleIcon from '@mui/icons-material/People';
import SchoolIcon from '@mui/icons-material/School';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Grid from '@mui/material/Unstable_Grid2';
import NavHeader from '@/components/NavHeader'
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import EditIcon from '@mui/icons-material/Edit';

dayjs.extend(customParseFormat);

const APP_URL = process.env.REACT_APP_APP_URL;

const BookSession = () => {

    const { token } = useSelector((state) => state.auth);
    const { id } = useParams();

    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const [isLoading, setIsLoading] = useState(false);

    const [defaultValue, setDefaultValue] = useState(false);
    const [trainer, setTrainer] = useState([]);
    const [AcademicClass, setClass] = useState([]);
    const [users, setUsers] = useState([]);
    const [subscriptions, setSubscriptions] = useState([]);
    const [open, setOpen] = useState(false);
    const [formError, setFormError] = useState(false);

    const navigate = useNavigate();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleFindManyUserSubscriptions = async (token, filter) => {
        try {
            const response = await findManyUserSubscriptions(token, {});
            if (response && !response.error) {
                const { data, meta } = response;
                if (Array.isArray(data)) {
                    setSubscriptions(data);
                } else {
                    setSubscriptions([]);
                }
            }
        } catch (error) {

        }
    }

    async function handleBookSession(event) {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const formData = {
            subscription_id: data.get('subscription_id'),
            session_id: id
        }
        const error = validate(formData, createSessionConstraint);
        if (!error) {
            try {
                setIsLoading(true)
                const response = await createUserSessions(token, formData);
                if (response && !response.error) {
                    const { id } = response;
                    showMessage('success', 'Data created successfully!');
                    if (id) {
                        navigate("/account/sessions/" + id);
                    }
                } else {
                    showMessage('error', response?.data?.message || t('An error occurred, please try again later'));
                }
            } catch (error) {
                showMessage('error', 'An error occurred, please try again later');
            } finally {
                setIsLoading(false);
            }
        }
        setFormError(error);
    }

    async function handleFindOneSession() {
        try {
            const response = await findOneSession(token, id);
            if (response && !response.error) {
                const { data } = response;
                if (data) {
                    const {
                        title,
                        description,
                        capacity,
                        trainer,
                        start_time,
                        end_time,
                        users
                    } = data;
                    const academicClass = data?.class;
                    setClass(academicClass);
                    setTrainer(trainer);
                    setUsers(users);
                    setDefaultValue({
                        title: title,
                        description: description,
                        capacity: capacity,
                        start_time: start_time,
                        end_time: end_time,
                        class: academicClass
                    });
                }
            } else {
                showMessage('error', response?.data?.message || t('An error occurred, please try again later'));
            }
        } catch (error) {
            console.log('error', error);
        }
    }

    useEffect(() => {
        if (token) {
            handleFindManyUserSubscriptions(token, {});
            handleFindOneSession();
        }
    }, [token])

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 540,
        bgcolor: 'background.paper',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };

    const createSessionConstraint = {
        subscription_id: {
            presence: true,
        }
    }


    if (!defaultValue) {
        return (
            <PageLoading />
        );
    }

    const classInfo = defaultValue.class;
    const start = dayjs(defaultValue.start_time);
    const end = dayjs(defaultValue.end_time);
    // Tính khoảng thời gian giữa hai thời điểm trong phút
    const durationInMinutes = end.diff(start, 'minute');

    const currentDayString = dayjs().format('MM/DD');
    const currentDay = dayjs(currentDayString, 'MM/DD');

    const sessionDateCompare = currentDay.isAfter(start);

    return (
            <Box sx={{ width: '100%' }}>
                <NavHeader
                    title="Book a session"
                    breadcrumb={[
                        {
                            title: 'Book a session'
                        }
                    ]}
                    backLink={`/trainer/sessions`}
                />
                <Grid container spacing={4}>
                    <Grid xs={12}>
                        <Box>
                            <Divider sx={{ mb: 2 }}>
                                <Chip
                                    sx={{ textTransform: 'uppercase', borderRadius: 0 }}
                                    label="Session information"
                                />
                            </Divider>
                            <Typography
                                sx={{
                                    fontWeight: 600,
                                    mb: 1,
                                    textTransform: 'uppercase'
                                }}>
                                {defaultValue?.title}
                            </Typography>
                            {
                                sessionDateCompare ? (
                                    <Typography sx={{ mb: 2 }}>
                                        (<span className="text-red-600">*</span>)
                                        This session has expired!</Typography>
                                ) : ""
                            }
                            <Box sx={{ mb: 3 }}>
                                {defaultValue?.description}
                            </Box>
                            <Box sx={{ mb: 3 }}>
                                {
                                    classInfo ?
                                        <Typography sx={{ mb: 2 }}>
                                            <SchoolIcon sx={{ mr: '3px' }} />
                                            Class: {classInfo?.title}
                                        </Typography>
                                        : ""
                                }
                                {
                                    start ?
                                        <Typography sx={{ mb: 2 }}>
                                            <CalendarMonthIcon sx={{ mr: '3px' }} />
                                            Schedule: {start.format('HH:mm')} - {end.format('HH:mm')} ({start.format('DD/MM/YYYY')})
                                        </Typography>
                                        : ""
                                }
                                {
                                    durationInMinutes ?
                                        <Typography sx={{ mb: 2 }}>
                                            <AccessTimeIcon sx={{ mr: '3px' }} />
                                            Duration: {durationInMinutes} minutes
                                        </Typography>
                                        : ""
                                }
                                {
                                    durationInMinutes ?
                                        <Typography sx={{ mb: 2 }}>
                                            <PeopleIcon sx={{ mr: '3px' }} />
                                            Capacity: {defaultValue?.capacity} / {users.length}
                                        </Typography>
                                        : ""
                                }
                            </Box>
                        </Box>
                        {
                            AcademicClass ? (
                                <Box>
                                    <Divider sx={{ mb: 2 }}>
                                        <Chip
                                            sx={{ textTransform: 'uppercase', borderRadius: 0 }}
                                            label="Class information"
                                        />
                                    </Divider>
                                    <Typography sx={{ mb: 2 }}>
                                        <strong>Title:</strong> {AcademicClass?.title}
                                    </Typography>
                                    <Typography sx={{ mb: 2 }}>
                                        <strong>Description:</strong> {trainer?.description}
                                    </Typography>
                                    <Typography sx={{ mb: 2 }}>
                                        <strong>Capacity:</strong> {trainer?.capacity}
                                    </Typography>
                                </Box>
                            ) : ""
                        }

                        {
                            trainer ? (
                                <Box>
                                    <Divider sx={{ mb: 2 }}>
                                        <Chip
                                            sx={{ textTransform: 'uppercase', borderRadius: 0 }}
                                            label="Trainer information"
                                        />
                                    </Divider>
                                    <Typography sx={{ mb: 2 }}>
                                        <strong>Full name:</strong> {trainer?.full_name}
                                    </Typography>
                                    <Typography sx={{ mb: 2 }}>
                                        <strong>Experience:</strong> {trainer?.experience}
                                    </Typography>
                                    <Typography sx={{ mb: 2 }}>
                                        <strong>Gender:</strong> {trainer?.gender}
                                    </Typography>
                                </Box>
                            ) : ""
                        }
                        <Stack direction="row" spacing={3}>
                            <Button
                                variant='contained'
                                as={Link}
                                to={`/trainer/sessions/${id}/edit`}
                                color='secondary'
                                startIcon={<EditIcon />}
                                sx={{
                                    padding: "10px 8px",
                                    borderRadius: 0,
                                    display: "inline-flex",
                                    mt: 2,
                                    mb: 2,
                                    textAlign: 'center',
                                    letterSpacing: 2
                                }}>
                                Edit session
                            </Button>
                            <Button
                                variant='contained'
                                as={Link}
                                to={`/trainer/sessions/${id}/checkin`}
                                color='secondary'
                                startIcon={<ChecklistRtlIcon />}
                                sx={{
                                    padding: "10px 8px",
                                    borderRadius: 0,
                                    display: "inline-flex",
                                    mt: 2,
                                    mb: 2,
                                    textAlign: 'center',
                                    letterSpacing: 2
                                }}>
                                Checkin session
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
    )
}

export default BookSession