import React, { useState } from 'react'
import PostPicker from './PostPicker';
import { Select, MenuItem, FormControl, FormLabel, Box  } from '@mui/material'
import { useTranslation } from 'react-i18next';
import { findManyMemberships } from '@/utils/Memberships';
import { findManyUsers } from '@/utils/Users';
import { findManyTrainers } from '@/utils/Trainers';
import { findManyClasses } from '@/utils/Classes';

const FilterSubscriptionSession = ({ filter, setFilter }) => {

  const { t } = useTranslation();
  
  const [classes, setClasses] = useState(false);
  const [user, setUser] = useState(false);
  const [open, setOpen] = useState(false);

  function handleChangeClasses(value){
    setClasses(value);
    setFilter((filter) => ({...filter, class_id: value?.id}))
  }

  function handleChangeTrainer(value){
    setUser(value);
    setFilter((filter) => ({...filter, trainer_id: value?.id}))
  }

  return (
    <Box>
        <div className='grid grid-cols-2 lg:grid-cols-4 gap-3 mb-5'>
           <PostPicker 
              label={t("Instructor")}
              getApi={findManyTrainers}
              values={[user]}
              optionKey={"full_name"}
              previewKey={"email"}
              labelStyle={{mb: 1, fontSize: 14}}
              onChange={(value) => handleChangeTrainer(value)}
            />
            <PostPicker 
              label={t("Class")}
              getApi={findManyClasses}
              values={[classes]}
              optionKey={"title"}
              labelStyle={{mb: 1, fontSize: 14}}
              onChange={(value) => handleChangeClasses(value)}
            />
            <FormControl>
              <FormLabel sx={{mb: 1, fontSize: 14}}>{t("Status")}</FormLabel>
              <Select 
                  fullWidth
                  value={filter?.status}
                  onChange={(e) => setFilter((filter) => ({
                    ...filter, 
                    status: e.target.value,
                    paged: 1
                  }))}
                  size='small'>
                  <MenuItem value="checked_in">{t("Checked in")}</MenuItem>
                  <MenuItem value="checked_out">{t("Checked out")}</MenuItem>
                  <MenuItem value="no_show">{t("No Show")}</MenuItem>
                  <MenuItem value="all">{t("All")}</MenuItem>
                </Select>
            </FormControl>
        </div>
    </Box>
  )
}

export default FilterSubscriptionSession