import { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import PageLoading from '@/components/PageLoading';
import NavHeader from '@/components/NavHeaderHome';

import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekday from 'dayjs/plugin/weekday';
import classnames from 'classnames';
import dayjs from 'dayjs';
import WeekView from './Components/WeekView';

import { findManySessions } from '@/utils/Sessions';
import { findManyClasses } from "@/utils/Classes";
import { ListItemText, MenuItem, MenuList, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Extend dayjs with plugins
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(weekOfYear);
dayjs.extend(weekday);

export default function EnhancedTable() {

    const [data, setData] = useState([]);
    const [step, setStep] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [classes, setClasses] = useState(false);

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [filter, setFilter] = useState({
        trainer_id: null,
        class_id: null,
        start_time: null,
        end_time: null,
        is_private: 0
    });

    useEffect(() => {
        if (filter?.start_time) {
            handleFindManyData(filter);
        }
    }, [filter])

    useEffect(() => {
        const start_time = dayjs().add(step, "week").startOf('week').format('YYYY-MM-DD HH:mm:ss'); // Chủ nhật
        const end_time = dayjs().add(step, "week").endOf('week').format('YYYY-MM-DD HH:mm:ss'); // Thứ 7
        setFilter({
            ...filter,
            start_time: start_time,
            end_time: end_time
        });
    }, [step])

    const handleFindManyData = async (params) => {
        try {
            setIsLoading(true);
            const response = await findManySessions(params);
            if (response && !response.error) {
                const { data } = response;
                setData(data);
            }
        } catch (error) {

        } finally {
            setIsLoading(false);
        }
    }

    async function handleFindManyClasses() {
        try {
            const response = await findManyClasses({
                is_private: 0
            });
            if (response && !response.error) {
                const { data } = response;
                if (data) {
                    setClasses(data);
                }
            }
        } catch (error) {

        }
    }

    useEffect(() => {
        handleFindManyClasses();
    }, [])

    return (
        <Box sx={{ width: '100%' }}>
            <NavHeader
                title={t("Book a session")}
                breadcrumb={[
                    {
                        title: t("Book a session")
                    }
                ]}
                endAction={<Button 
                    onClick={() => navigate("/book-trainer")}
                    variant='contained' 
                    sx={{borderRadius: 0, 
                    background: "#000"}}>
                    {t("Book a private session")}
                    </Button>}
            />
            <Box sx={{ py: 2 }}>
                <Stack direction="row" justifyContent="space-between" spacing={3}>
                    <Button
                        onClick={() => setStep((step) => step - 1)}
                        variant='outlined'
                        sx={{
                            borderRadius: 0,
                            letterSpacing: 2,
                            fontWeight: 600
                        }}
                        disabled={step == -1}
                        color="secondary">
                        {t("Prev week")}
                    </Button>
                    <Button
                        onClick={() => setStep((step) => step + 1)}
                        variant='outlined'
                        disabled={step == 2}
                        sx={{
                            borderRadius: 0,
                            letterSpacing: 2,
                            fontWeight: 600
                        }}
                        color="secondary">
                        {t("Next week")}
                    </Button>
                </Stack>
            </Box>
            <Box>
                <MenuList sx={{ display: 'flex', flexWrap: "wrap", gap: 1 }}>
                    {
                        Array.isArray(classes) && classes.length ?
                            classes.map((val) => {
                                return (
                                    <MenuItem
                                        className={classnames({
                                            'text-center p-3': true,
                                            '!bg-black !text-white': val.id === filter.class_id,
                                            '!border !border-black': val.id !== filter.class_id
                                        })}
                                        onClick={() => setFilter({ ...filter, class_id: val.id })}
                                        sx={{ flex: 1, border: "1px solid" }}
                                        key={val.id}>
                                        <ListItemText
                                            sx={{
                                                textTransform: "uppercase",
                                                fontWeight: 700
                                            }}>
                                            {val.title}
                                        </ListItemText>
                                    </MenuItem>
                                )
                            })
                            : ""
                    }
                </MenuList>
            </Box>
            {isLoading ? <PageLoading height={320} /> : <WeekView data={data} step={step} />}
        </Box>
    );
}