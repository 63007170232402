import PageLoading from '@/components/PageLoading';
import React, { useEffect, useState } from 'react'
import { useToasterContext } from '@/utils/ToasterContext';
import { findOneTrainer } from '@/utils/UserTrainers';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import dayjs from 'dayjs';
import classNames from 'classnames';
import NavHeader from '@/components/NavHeaderHome';
import Grid from '@mui/material/Unstable_Grid2';
import { findManyTrainerSchedules } from '@/utils/TrainerSchedule';
import { useTranslation } from 'react-i18next';

const APP_URL = process.env.REACT_APP_APP_URL;

const ViewTrainer = () => {

    const { id } = useParams();
    const { token } = useSelector((state) => state.auth);
    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const [data, setData] = useState(false);
    const [pageStatus, setPageStatus] = useState('idle');
    const [calendar, setCalendar] = useState(false);
    const [schedule, setSchedule] = useState([]);
    const [step, setStep] = useState(0);
    const navigate = useNavigate();

    const { t } = useTranslation();

    const handleFindManyData = async () => {
        try {
            startProgress();
            const response = await findOneTrainer(id);
            if (response && !response.error) {
                const { data } = response;
                setData(data)
            }
            setPageStatus("complete");
        } catch (error) {
            console.log('error', error);
        } finally {
            completeProgress();
        }
    }

    async function handleFindTrainerSchedule() {
        try {
            const monthYear = dayjs().add(step, 'month').format("YYYY-MM")
            const filter = {
                month: monthYear
            };
            const response = await findManyTrainerSchedules(id, filter);
            if (response && !response.error) {
                setSchedule(response.data);
            } else {
                setSchedule([])
            }
        } catch (error) {
            console.error("error", error);
        }

    }

    function calculateAge(birthday) {
        const now = dayjs();
        const years = now.diff(birthday, 'year');
        return years;
    }

    function createMonthCalendar(month) {
        const startOfMonth = month.startOf('month');
        const endOfMonth = month.endOf('month');
        const daysInMonth = endOfMonth.date();
        const today = dayjs();

        let calendar = [];
        let currentDay = startOfMonth;

        // Thêm các ngày của tháng trước
        const startDayOfWeek = startOfMonth.day(); // 0: Chủ nhật, 1: Thứ 2, ..., 6: Thứ 7
        if (startDayOfWeek !== 0) { // Nếu ngày bắt đầu không phải Chủ nhật
            for (let i = startDayOfWeek - 1; i >= 1; i--) {
                const dayFromPrevMonth = startOfMonth.subtract(i + 1, 'day');
                calendar.push({
                    day: dayFromPrevMonth.date(),
                    fullDate: dayFromPrevMonth.format('DD-MM-YYYY'),
                    dayOfWeek: dayFromPrevMonth.format('ddd'),
                    active: dayFromPrevMonth.isSame(today, 'day'),
                    disabled: dayFromPrevMonth.isBefore(today, 'day'),
                });
            }
        }

        // Lặp qua từng ngày trong tháng
        while (currentDay.isSameOrBefore(endOfMonth)) {
            calendar.push({
                day: currentDay.date(),
                fullDate: currentDay.format('DD-MM-YYYY'),
                dayOfWeek: currentDay.format('ddd'),
                active: currentDay.isSame(today, 'day'),
                disabled: currentDay.isBefore(today, 'day'),
            });
            currentDay = currentDay.add(1, 'day');
        }

        // Thêm các ngày của tháng sau nếu mảng chưa đủ 35 ngày
        const totalDays = calendar.length;
        const daysToAdd = 35 - totalDays;
        for (let i = 0; i < daysToAdd; i++) {
            const dayFromNextMonth = endOfMonth.add(i + 1, 'day');
            calendar.push({
                day: dayFromNextMonth.date(),
                fullDate: dayFromNextMonth.format('DD-MM-YYYY'),
                dayOfWeek: dayFromNextMonth.format('ddd'),
                active: dayFromNextMonth.isSame(today, 'day'),
                disabled: dayFromNextMonth.isBefore(today, 'day'),
            });
        }

        return calendar;
    }


    useEffect(() => {
        const currentMonth = dayjs().startOf('month');
        const calendar = createMonthCalendar(currentMonth);
        setCalendar(calendar);
    }, [])

    useEffect(() => {
        const currentMonth = dayjs().startOf('month').add(step, 'month');
        const calendar = createMonthCalendar(currentMonth);
        setCalendar(calendar);
        handleFindTrainerSchedule();
    }, [step])

    useEffect(() => {
        handleFindManyData();
        handleFindTrainerSchedule();
    }, [])

    const currentDay = dayjs();

    if (pageStatus != 'complete') {
        return (
            <PageLoading />
        )
    }

    return (
        <div>
            <NavHeader
                title={t("Book private session")}
                breadcrumb={[
                    {
                        title: t("Book private session")
                    }
                ]}
                backLink="/book-trainer/"
            />
            <Grid container spacing={5}>
                <Grid xs={12} lg={4}>
                    <div className=''>
                        <img
                            className='h-[180px] lg:h-[300px] object-cover object-top'
                            src={data?.thumbnail?.file_path ? APP_URL + "/" + data.thumbnail.file_path : "https://placehold.co/400x300"}
                            alt="" />
                        <div className="p-3">
                            <Typography component="h3" className='!font-bold uppercase text-center'>{data?.full_name}</Typography>
                        </div>
                    </div>
                </Grid>
                <Grid xs={12} lg={8}>
                    <Typography sx={{
                        fontWeight: 600,
                        textTransform: 'uppercase',
                        letterSpacing: 2,
                        mb: 1
                    }}>
                        {t("Select date")}
                    </Typography>
                    <div className="flex flex-row justify-between mb-3">
                        <button
                            onClick={() => setStep((step) => step - 1)}
                            className="font-normal uppercase tracking-[1px]">
                            {t("Prev")}
                        </button>
                        <Typography className="!font-bold uppercase tracking-[1px]">
                            {dayjs().add(step, 'month').format("MMMM, YYYY")}
                        </Typography>
                        <button onClick={() => setStep((step) => step + 1)}
                            type='plain'
                            textColor='#333'
                            className="font-normal uppercase tracking-[1px]">
                            {t("Next")}
                        </button>
                    </div>
                    <div className="grid grid-cols-7">
                        {
                            Array.isArray(calendar) && calendar.length ? calendar.map((val, index) => {
                                const isActive = currentDay.startOf("day").isBefore(dayjs(val.fullDate, "DD-MM-YYYY"));
                                const { day, dayOfWeek, fullDate } = val;
                                const avaiableDay = schedule.find((_v) => dayjs(_v.schedule, 'YYYY-MM-DD').format("DD-MM-YYYY") == val.fullDate);
                                return (
                                    <div key={index} className='cursor-pointer' onClick={() => !avaiableDay?.is_off && avaiableDay ? navigate(`/book-trainer/${id}/${fullDate}`) : showMessage("error", "Session unavaiabled!")}>
                                        <div className={
                                            classNames({
                                                'border border-neutral-300 p-3': true,
                                                'bg-neutral-200 cursor-not-allowed text-neutral-600': !avaiableDay,
                                                'bg-neutral-200 cursor-not-allowed': !avaiableDay || !isActive,
                                                'bg-red-300': avaiableDay?.is_off,
                                            })
                                        }>
                                            <strong >{day}</strong>
                                            <Typography
                                                sx={{
                                                    letterSpacing: 2,
                                                    fontSize: 12,
                                                    textTransform: 'uppercase'
                                                }}>
                                                {dayOfWeek}
                                            </Typography>
                                        </div>
                                    </div>
                                )
                            }) : ""
                        }
                    </div>
                </Grid>
            </Grid>
        </div >
    )
}

export default ViewTrainer
