
import { useEffect, useState, useRef } from 'react';
import { createSubscription } from '@/utils/Subscriptions';
import { useSelector } from 'react-redux';
import { useToasterContext } from '@/utils/ToasterContext'
import { LoadingButton } from '@mui/lab'
import { useNavigate, useParams } from 'react-router-dom'

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import NavHeader from '@/components/NavHeader';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import validate from 'validate.js'
import PostPicker from '@/components/PostPicker'
import dayjs from 'dayjs'
import { findManyUsers } from '@/utils/Users';
import { findManyMemberships } from '@/utils/Memberships';
import { FormControlLabel, MenuItem, Select, Switch } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTranslation } from 'react-i18next';

const CreateSubscriptionPage = () => {

    const { t } = useTranslation();
    const { token } = useSelector((state) => state.auth);
    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const [isLoading, setIsLoading] = useState(false);
    const [formError, setFormError] = useState(false);
    const [user, setUser] = useState(null);
    const [membership, setMembership] = useState(null);
    const [isTriggerActive, setIsTriggerActive] = useState(0);
    const [startDate, setStartDate] = useState(dayjs());
    const [endDate, setEndDate] = useState(dayjs().add(1, "day"));

    const navigate = useNavigate();
    const formRef = useRef();

    async function handleCreateData(token, data) {
        try {
            setIsLoading(true);
            startProgress();
            const response = await createSubscription(token, data);
            const notify = data?.sent_notify;

            if (response && !response.error) {
                const { data } = response;
                if (data?.id) {
                    showMessage('success', t('Data created successfully!'));
                    if(notify){
                        showMessage('success', t("Registration information has been sent to the user's email"));
                    } 
                    navigate("/admin/subscriptions/" + data.id);
                } else {
                    showMessage('error', t('An error occurred, please try again later!'));
                }
            }
        } catch (error) {
            showMessage('error', t('An error occurred, please try again later!'));
        } finally {
            setIsLoading(false);
            completeProgress(true);
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const notify = data.get('sent_notify');

        let dataForm = {
            user_id: user?.id,
            membership_id: membership?.id,
            max_sessions: data.get('max_sessions'),
            sessions_used: data.get('sessions_used'),
            trigger_active: isTriggerActive ? 1 : 0,
            status: data.get('status'),
            sent_notify: notify == 'on' ? 1 : 0
        }

        if(!isTriggerActive) {
            dataForm = {
                ...dataForm,
                start_date: dayjs(data.get('start_date'), "DD/MM/YYYY").format("YYYY-MM-DD"),
                end_date: dayjs(data.get('end_date'), "DD/MM/YYYY").format("YYYY-MM-DD"),
            }
        }

        const error = validate(dataForm, constraints);

        if (!error) {
            handleCreateData(token, dataForm)
        } else {
            console.log('error', error);
            showMessage('error', t("One or more input fields are invalid!"));
        }

        setFormError(error);
    }

    useEffect(() => {
        if (membership) {
            const form = formRef.current;
            const { is_all_class, is_private, sessions, duration_days, trigger_active } = membership;
            setIsTriggerActive(trigger_active);
            if (duration_days) {
                setEndDate(dayjs().add(duration_days, "day"));
            }
            form.elements['max_sessions'].value = sessions;
            form.elements['status'].value = "active";
        }
        console.log('membership', membership);
    }, [membership])

    // Custom validator to ensure end_time is after start_time
    validate.validators.timeAfter = function (value, options, key, attributes) {
        const startDate = dayjs(attributes.start_date, 'YYYY-MM-DD');
        const endDate = dayjs(value, 'YYYY-MM-DD');;
        if (endDate.isBefore(startDate)) {
            return options.message || "must be after start time";
        }
    };

    let constraints = {
        user_id: {
            presence: true,
        },
        membership_id: {
            presence: true,
        },
        max_sessions: {
            presence: true,
        },
        status: {
            presence: true,
        }
    };

    if(!isTriggerActive){
        constraints = {
            ...constraints,
            start_date: {
                presence: { allowEmpty: false, message: "is required" },
            },
            end_date: {
                presence: { allowEmpty: false, message: "is required" },
                timeAfter: {
                    message: t("must be after start time")
                }
            },
        }
    }

    return (
        <Box sx={{ width: '100%' }}>
            <NavHeader
                title={t("Create Subscription")}
                breadcrumb={[
                    {
                        title: 'Subscriptions',
                        link: '/admin/subscriptions/'
                    },
                    {
                        title: t('Create Subscription')
                    }
                ]}
                backLink="/admin/subscriptions"
            />
            <Box ref={formRef} component="form" onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={8}>
                        <Box sx={{ mb: 1 }}>
                            <PostPicker
                                label={t("Select User")}
                                name="user_id"
                                getApi={findManyUsers}
                                values={[user]}
                                onChange={(value) => {
                                    setUser(value)
                                }}
                                required
                                optionKey="email"
                                previewKey="id"
                            />
                        </Box>
                        <Box sx={{ mb: 1 }}>
                            <PostPicker
                                label={t("Select membership")}
                                name="membership_id"
                                getApi={findManyMemberships}
                                onChange={(value) => {
                                    setMembership(value)
                                }}
                                required
                                optionKey="title"
                                previewKey="is_private"
                            />
                        </Box>
                        <FormControl sx={{mb: 2}}>
                            <FormControlLabel
                                control={<Switch />}
                                checked={isTriggerActive}
                                onChange={() => setIsTriggerActive(!isTriggerActive)}
                                label="Automatically activate membership after registering for a session" />
                        </FormControl>
                        { 
                            !isTriggerActive ? (
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.start_date} required>
                                            <FormLabel>{t("Start date")}</FormLabel>
                                            <DatePicker
                                                name="start_date"
                                                value={startDate}
                                                onChange={(value) => setStartDate(value)}
                                            />
                                            {formError?.start_date ? <FormHelperText>{formError?.start_date[0]}</FormHelperText> : ""}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.end_date} required>
                                            <FormLabel>{t("End date")}</FormLabel>
                                            <DatePicker
                                                name="end_date"
                                                value={endDate}
                                                onChange={(value) => setEndDate(value)}
                                            />
                                            {formError?.end_date ? <FormHelperText>{formError?.end_date[0]}</FormHelperText> : ""}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            ) : ""
                        }
                        <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.max_sessions} required>
                            <FormLabel>{t("Max sessions (-1 is unlimited)")}</FormLabel>
                            <TextField
                                name="max_sessions"
                                type="number"
                                error={formError?.max_sessions}
                            />
                            {formError?.duration_days ? <FormHelperText>{formError?.duration_days[0]}</FormHelperText> : ""}
                        </FormControl>
                        <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.sessions_used} required>
                            <FormLabel>{t("Sessions used")}</FormLabel>
                            <TextField
                                name="sessions_used"
                                type="number"
                                defaultValue={0}
                                error={formError?.sessions_used}
                            />
                            {formError?.sessions_used ? <FormHelperText>{formError?.sessions_used[0]}</FormHelperText> : ""}
                        </FormControl>
                        <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.sessions_used} required>
                            <FormLabel>{t("Status")}</FormLabel>
                            <Select
                                name="status"
                                defaultValue={"active"}
                                error={formError?.status}
                            >
                                <MenuItem value="active">{t("Active")}</MenuItem>
                                <MenuItem value="freeze">{t("Freeze")}</MenuItem>
                                <MenuItem value="inactive">{t("Inactive")}</MenuItem>
                            </Select>
                            {formError?.status ? <FormHelperText>{formError?.status[0]}</FormHelperText> : ""}
                        </FormControl>
                        <FormControl sx={{ width: "100%", mb: 3 }}>
                            <FormControlLabel
                                control={<Switch />}
                                label={t("Sent an email notify to subscription")}
                                name="sent_notify"
                            />
                        </FormControl>
                        <FormControl>
                            <LoadingButton
                                variant='contained'
                                color='primary'
                                size='lg'
                                type='submit'
                                loading={isLoading}>
                                {t("Create subscription")}
                            </LoadingButton>
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default CreateSubscriptionPage
