import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs';
import { Box, Button, Typography } from '@mui/material';

import EditSchedule from './EditSchedule';
import { findManyTrainerSchedules } from '@/utils/TrainerSchedule';
import { useSelector } from 'react-redux';
import NavHeader from '@/components/NavHeaderHome';
import { useTranslation } from 'react-i18next';
import PageLoading from '@/components/PageLoading';
import ErrorPage from '@/pages/ErrorPage';

const index = () => {

    const { t } = useTranslation();
    const [calendar, setCalendar] = useState(false);
    const [step, setStep] = useState(0);
    const [edit, setEdit] = useState(false);
    const [data, setData] = useState([]);
    const [pageInit, setPageInit] = useState(false);
    const { user } = useSelector((state) => state.auth);

    async function handleFindData(trainer_id) {
        if(!trainer_id){
            setPageInit("complete");
        }
        try {
            const monthYear = dayjs().add(step, 'month').format("YYYY-MM")
            const filter = {
                month: monthYear
            };
            const response = await findManyTrainerSchedules(trainer_id, filter);
            if (response && !response.error) {
                setData(response.data);
            } else {
                setData([])
            }
        } catch (error) {

        } finally {
            setPageInit("complete");
        }
    }

    useEffect(() => {
        if (user?.trainer_information?.id) {
            handleFindData(user.trainer_information.id);
        }
    }, [user])

    function createMonthCalendar(month) {
        const startOfMonth = month.startOf('month');
        const endOfMonth = month.endOf('month');
        const daysInMonth = endOfMonth.date();
        const today = dayjs();

        let calendar = [];
        let currentDay = startOfMonth;

        // Thêm các ngày của tháng trước
        const startDayOfWeek = startOfMonth.day(); // 0: Chủ nhật, 1: Thứ 2, ..., 6: Thứ 7
        if (startDayOfWeek !== 0) { // Nếu ngày bắt đầu không phải Chủ nhật
            for (let i = startDayOfWeek - 1; i >= 1; i--) {
                const dayFromPrevMonth = startOfMonth.subtract(i + 1, 'day');
                calendar.push({
                    day: dayFromPrevMonth.date(),
                    fullDate: dayFromPrevMonth.format('YYYY-MM-DD'),
                    dayOfWeek: dayFromPrevMonth.format('ddd'),
                    isCurrentMonth: false,
                    active: dayFromPrevMonth.isSame(today, 'day'),
                    disabled: dayFromPrevMonth.isBefore(today, 'day'),
                });
            }
        }

        // Lặp qua từng ngày trong tháng
        while (currentDay.isSameOrBefore(endOfMonth)) {
            calendar.push({
                day: currentDay.date(),
                fullDate: currentDay.format('YYYY-MM-DD'),
                dayOfWeek: currentDay.format('ddd'),
                isCurrentMonth: true,
                active: currentDay.isSame(today, 'day'),
                disabled: currentDay.isBefore(today, 'day'),
            });
            currentDay = currentDay.add(1, 'day');
        }

        // Thêm các ngày của tháng sau nếu mảng chưa đủ 35 ngày
        const totalDays = calendar.length;
        const daysToAdd = 35 - totalDays;
        for (let i = 0; i < daysToAdd; i++) {
            const dayFromNextMonth = endOfMonth.add(i + 1, 'day');
            calendar.push({
                day: dayFromNextMonth.date(),
                fullDate: dayFromNextMonth.format('YYYY-MM-DD'),
                dayOfWeek: dayFromNextMonth.format('ddd'),
                isCurrentMonth: false,
                active: dayFromNextMonth.isSame(today, 'day'),
                disabled: dayFromNextMonth.isBefore(today, 'day'),
            });
        }

        return calendar;
    }


    useEffect(() => {
        const currentMonth = dayjs().startOf('month').add(step, 'month');
        const calendar = createMonthCalendar(currentMonth);
        setCalendar(calendar);
        if(user?.trainer_information?.id){
            handleFindData(user?.trainer_information?.id)
        }
    }, [step]);

    if(!user?.trainer_information?.id){
        return (
            <ErrorPage />
        )
    }

    if(pageInit != 'complete'){
        return(
            <PageLoading />
        )
    }

    return (
        <Box>
            <NavHeader
                title={t("Your Private Schedule")}
                breadcrumb={[
                    {
                        title: t('Your Private Schedule')
                    }
                ]}
            />
            <Box>
                <Box className='flex justify-between gap-3 mb-5'>
                    <Button
                        onClick={() => setStep((step) => step - 1)}
                        variant='contained'>
                        {t("Prev month")}
                    </Button>
                    <Typography className='font-bold uppercase'>
                        {dayjs().add(step, 'month').format("MMMM, YYYY")}
                    </Typography>
                    <Button onClick={() => setStep((step) => step + 1)}
                        type='plain'
                        textColor='#333'
                        variant='contained'>
                        {t("Next month")}
                    </Button>
                </Box>
                <div className='grid grid-cols-7 gap-[5px]'>
                    {
                        Array.isArray(calendar) && calendar.length ? calendar.map((val, index) => {
                            const { day, dayOfWeek, fullDate } = val;
                            let avaiableDay = {};
                            if (Array.isArray(calendar) && calendar.length) {
                                avaiableDay = data.find((_v) => _v.schedule == val.fullDate);
                            }
                            return (
                                <Box
                                    key={index}
                                    sx={{
                                        borderWidth: 1,
                                        padding: "8px",
                                        borderColor: "#EEF2F6",
                                        backgroundColor: avaiableDay && avaiableDay?.is_off ? "#FFAB91" : !avaiableDay ? "#e8e8e8" : "#fff",
                                    }}>
                                    <div
                                        className="cursor-pointer"
                                        onClick={() => setEdit(fullDate)}>
                                        <p className='font-bold'>
                                            {day} {avaiableDay?.is_off ? " / Off" : ""}
                                        </p>
                                        <p className='uppercase'>
                                            {dayOfWeek}
                                        </p>
                                        <p className='uppercase text-[12px]'>
                                            {avaiableDay ? t("Edited") : t("By default")}
                                        </p>
                                    </div>
                                </Box>
                            )
                        }) : ""
                    }
                </div>
            </Box>
            <EditSchedule
                date={edit}
                setDate={setEdit}
                reload={() => handleFindData(user?.trainer_information?.id)}
            />
        </Box>
    )
}

export default index
