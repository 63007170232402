import { Box, TableCell, Stack, Paper, TableContainer, Table, TableBody, TableRow, Avatar, TableHead, FormControl, FormControlLabel, Switch } from '@mui/material'
import { Fragment, useEffect, useState } from 'react'
import { findOneSession, TrainerCheckInUser, TrainerCheckOutUser, TrainerNoShowUser } from '@/utils/TrainerSessions'
import { useSelector } from 'react-redux'
import { useToasterContext } from '@/utils/ToasterContext'
import { Link, useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab'

import PageLoading from '@/components/PageLoading'
import dayjs from 'dayjs';
import NavHeader from '@/components/NavHeader';

import { getInitials } from '@/utils/Hooks'
import { useTranslation } from 'react-i18next'
import { updateUserSessions } from '@/utils/UserInfomations'

const CheckinSession = () => {

    const { token } = useSelector((state) => state.auth);
    const { id } = useParams();
    const { t } = useTranslation();
    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const [isLoading, setIsLoading] = useState(false);

    const [defaultValue, setDefaultValue] = useState(false);
    const [trainer, setTrainer] = useState([]);
    const [AcademicClass, setClass] = useState([]);
    const [users, setUsers] = useState([]);

    async function handleUpdateUserSession (session_id, data) {
        try {
            startProgress();
            const response = await updateUserSessions(token, session_id, data);
            if (response && !response.error) {
                handleFindOneSession();
            } else {
                showMessage("error", response?.data?.message || t("An error occurred, please try later"));
            }
        } catch (error) {
            showMessage("error", t("An error occurred, please try later"));
        } finally {
            completeProgress();
            // location.reload();
        }
    };

    async function handleFindOneSession() {
        try {
            startProgress();
            const response = await findOneSession(token, id);
            if (response && !response.error) {
                const { data } = response;
                if (data) {
                    const {
                        title,
                        description,
                        capacity,
                        trainer,
                        start_time,
                        end_time,
                        users
                    } = data;
                    const academicClass = data?.class;
                    setClass(academicClass);
                    setTrainer(trainer);
                    const filterUsers = users.filter((val) => val?.pivot?.status == "active");
                    setUsers(filterUsers);
                    setDefaultValue({
                        title: title,
                        description: description,
                        capacity: capacity,
                        start_time: start_time,
                        end_time: end_time,
                        class: academicClass
                    });
                }
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            completeProgress();
        }
    }

    const handleNoShowUser = async (id) => {
        try {
            setIsLoading("no_show_" + id);
            const response = await TrainerNoShowUser(token, id);
            if (response && !response.error) {
                showMessage('success', t('Successfully updated no show status!'));
                handleFindOneSession();
            } else {
                showMessage('error', response?.data?.message || t('An error occurred, please try again later'));
            }
        } catch (error) {
            showMessage('error', t('An error occurred, please try again later'));
        } finally {
            setIsLoading(false);
        }
    }

    const handleCheckInUser = async (id) => {
        try {
            setIsLoading("check_in_" + id);
            const response = await TrainerCheckInUser(token, id);
            if (response && !response.error) {
                showMessage('success', t('Successfully updated check-in status!'));
                handleFindOneSession();
            } else {
                showMessage('error', response?.data?.message || t('An error occurred, please try again later'));
            }
        } catch (error) {
            showMessage('error', t('An error occurred, please try again later'));
        } finally {
            setIsLoading(false);
        }
    }

    const handleCheckOutUser = async (id) => {
        try {
            setIsLoading("check_out_" + id);
            const response = await TrainerCheckOutUser(token, id);
            if (response && !response.error) {
                showMessage('success', t('Successfully updated check-out status!'));
                handleFindOneSession();
            } else {
                showMessage('error', response?.data?.message || t('An error occurred, please try again later'));
            }
        } catch (error) {
            showMessage('error', t('An error occurred, please try again later'));
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (token) {
            handleFindOneSession();
        }
    }, [token])

    if (!defaultValue) {
        return (
            <PageLoading />
        );
    }

    const headCells = [
        {
            id: 'user_info',
            numeric: false,
            disablePadding: false,
            label: 'User Info',
        },
        {
            id: 'Subscription',
            numeric: false,
            disablePadding: false,
            label: 'Subscription',
        },
        {
            id: 'remaining',
            numeric: false,
            disablePadding: false,
            label: 'Remaining',
        },
        {
            id: 'expiry',
            numeric: false,
            disablePadding: false,
            label: 'Expiry date',
        },
        {
            id: 'noshow',
            numeric: false,
            disablePadding: false,
            label: 'No show',
        },
        {
            id: 'check_in',
            numeric: false,
            disablePadding: false,
            label: 'Check in',
        },
        {
            id: 'check_out',
            numeric: false,
            disablePadding: false,
            label: 'Check out',
        },
        {
            id: 'apply_bonus',
            numeric: false,
            disablePadding: false,
            label: 'Apply bonus',
        }
    ];

    function EnhancedTableHead(props) {
        const { order, orderBy } = props;
        const { t } = useTranslation();
        return (
            <TableHead>
                <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <p sx={{ fontWeight: 600 }}>
                                {t(headCell.label)}
                            </p>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    const RowData = ({ rowData }) => {
        const { email, subscription, pivot, display_name, membership } = rowData;
        return (
            <Fragment>
                <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    sx={{ cursor: 'pointer' }}>
                    <TableCell>
                        <Link to={"/admin/users/" + rowData?.id}>
                            <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center">
                                <Avatar       
                                    sx={{
                                        bgcolor: "#000",
                                    }} 
                                    children={getInitials(pivot?.is_shared ? pivot?.name_shared : display_name || rowData?.email)} />
                                <div>
                                    <p>
                                        <strong>{pivot?.is_shared ? pivot?.name_shared : display_name || rowData?.email}</strong>
                                    </p>
                                    <p className='text-sm'>
                                        {rowData?.email || "-"}
                                    </p>
                                </div>
                            </Stack>
                        </Link>
                    </TableCell>
                    <TableCell>
                        {
                            membership ? (
                                <Link to={"/admin/memberships/" + membership?.id}>
                                    <p sx={{fontWeight: 600}}>
                                        {membership?.title}
                                    </p>
                                </Link>
                            ): ("-")
                        }
                    </TableCell>
                    <TableCell>
                        <p>
                            {subscription ? subscription?.max_sessions == "-1" ? "Unlimited" : `${subscription?.max_sessions - subscription?.sessions_used}/${subscription?.max_sessions}` : "-"}
                        </p>
                    </TableCell>
                    <TableCell>
                        {
                            subscription ? (
                                <p sx={{fontWeight: 600}}>
                                    {subscription?.end_date ? dayjs(subscription?.end_date, "YYYY-MM-DD").format("DD/MM/YYYY") : "-"}
                                </p>
                            ): ("-")
                        }
                    </TableCell>
                    <TableCell>
                        <p>
                            {rowData?.pivot?.no_show ? t("No show") : (
                                <LoadingButton
                                    loading={isLoading == `no_show_${rowData.id}`}
                                    sx={{borderRadius: 0}}
                                    size='small'
                                    onClick={() => { handleNoShowUser(pivot.id) }} 
                                    variant='outlined' 
                                    color="secondary">
                                    {t('No show')}
                                </LoadingButton>
                            )}
                        </p>
                    </TableCell>
                    <TableCell>
                        <p>
                            {pivot?.check_in_time ? dayjs(pivot.check_in_time).format("HH:mm A, DD/MM/YYYY") : (
                                <LoadingButton
                                    loading={isLoading == `check_in_${rowData.id}`}
                                    sx={{borderRadius: 0}}
                                    onClick={() => { handleCheckInUser(pivot.id)}} 
                                    variant='contained' 
                                    size='small'
                                    color="secondary">
                                    {t('Check-in')}
                                </LoadingButton>
                            )}
                        </p>
                    </TableCell>
                    <TableCell>
                        <p>
                            {pivot?.check_out_time ? dayjs(pivot.check_out_time).format("HH:mm A, DD/MM/YYYY") : (
                                <LoadingButton
                                    loading={isLoading == `check_out_${rowData.id}`}
                                    sx={{borderRadius: 0}}
                                    size='small'
                                    onClick={() => {
                                        if (pivot?.check_out_time) {
                                            showMessage('error', 'This user has checked out!')
                                        } else {
                                            handleCheckOutUser(pivot.id)
                                        }
                                    }} variant='outlined' color="secondary">
                                    {t('Check-Out')}
                            </LoadingButton>
                            )}
                        </p>
                    </TableCell>
                    <TableCell>
                        <FormControl sx={{ width: "100%", mb: 3 }}>
                            <FormControlLabel
                                control={<Switch />}
                                checked={pivot?.apply_bonus}
                                onChange={() => handleUpdateUserSession(pivot?.id, {
                                    apply_bonus: pivot?.apply_bonus ? 0 : 1
                                })}
                                name="un_send_email"
                            />
                        </FormControl>
                    </TableCell>
                </TableRow>
            </Fragment>
        )
    }

    return (
        <Box sx={{ width: '100%' }}>
            <NavHeader
                title="Session checkin"
                breadcrumb={[
                    {
                        title: 'Session checkin'
                    }
                ]}
                backLink={`/admin/sessions/${id}`}
            />
           <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 640, maxWidth: "calc(100vw - 54px)" }}>
                    <Table
                        stickyHeader aria-label="sticky table"
                        size='medium'>
                        <EnhancedTableHead
                            rowCount={users?.length}
                        />
                        <TableBody>
                            {users.map((row, index) => {
                                return (
                                    <RowData key={index} rowData={row}/>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    )
}

export default CheckinSession