import { useState, useEffect } from 'react';
import { alpha } from '@mui/material/styles';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import Avatar from '@mui/material/Avatar';
import AddIcon from '@mui/icons-material/Add';
import { deleteSession, findManySessions } from '@/utils/Sessions';

import ConfirmDelete from '@/components/ConfirmDelete';
import NavHeader from '@/components/NavHeader';
import { Select, Stack, MenuItem, FormControl, FormLabel, InputLabel } from '@mui/material';

import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekday from 'dayjs/plugin/weekday';
import dayjs from 'dayjs';
import ActionCell from '@/components/ActionCell';
import { useToasterContext } from '@/utils/ToasterContext';
import { useTranslation } from 'react-i18next';
import { findManyUserSessions } from '@/utils/Users';
import { useSelector } from 'react-redux';
import FilterTable from '@/components/FilterTable';
import PageLoading from '@/components/PageLoading';

const headCells = [
    {
        id: 'class',
        numeric: false,
        label: 'Class',
    },
    {
        id: 'users',
        label: 'Trainer',
    },
    {
        id: 'capacity',
        label: 'Capacity',
    },
    {
        id: 'start_time',
        label: 'Start in',
    },
    {
        id: 'duration',
        label: 'Duration',
    },
];

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const { t } = useTranslation();
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <Typography sx={{ fontWeight: 600 }}>
                            {t(headCell.label)}
                        </Typography>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}


function EnhancedTableToolbar(props) {
    const { numSelected } = props;
    const { t } = useTranslation();
    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <>
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {numSelected} {t("selected")}
                    </Typography>
                    <Tooltip title={t("Delete")}>
                        <IconButton>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </>
            ) : ""
            }
        </Toolbar>
    );
}

const TableUserSessions = () => {

    const { id } = useParams();
    const { t } = useTranslation();
    const { token } = useSelector((state) => state.auth)
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [deleteItem, setDeleteItem] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [pageInit, setPageInit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { showMessage } = useToasterContext();
    const [searchParams, setSearchParams] = useSearchParams();
    const [filter, setFilter] = useState({
        search: "",
        order: "desc",
        paged: 1,
        per_page: 12,
        start_date: null,
        end_date: null
    });
    
    const [data, setData] = useState([]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const handleFindManyData = async (params) => {
        try {
            setIsLoading(true);
            const response = await findManyUserSessions(token, id, params);
            if (response && !response.error) {
                const { data } = response;
                if(Array.isArray(data) && data?.length) {
                    setData(data);
                } else {
                    setData([]);
                }
            }
        } catch (error) {

        } finally {
            setIsLoading(false);
            setPageInit("complete");
        }
    }

    useEffect(() => {
        if (pageInit == 'complete') {
            let filterParams = {};
            for (const key in filter) {
                if (Object.hasOwnProperty.call(filter, key)) {
                    const element = filter[key];
                    if (element) {
                        filterParams[key] = element;
                    }
                }
            }
            handleFindManyData(filterParams);
        }
    }, [filter])

    useEffect(() => {
        if(token) {
            const initFilter = {
                search: searchParams.get('search'),
                order: searchParams.get('order'),
                start_date: searchParams.get('start_date'),
                end_date: searchParams.get('end_date'),
                paged: searchParams.get('paged'),
                per_page: searchParams.get('per_page'),
            };
            let filterParams = {};
            for (const key in initFilter) {
                if (Object.hasOwnProperty.call(initFilter, key)) {
                    const element = initFilter[key];
                    if (element != null) {
                        filterParams[key] = element;
                    }
                }
            }
            setFilter((filter) => ({
                ...filter,
                ...filterParams
            }));
            handleFindManyData(filterParams);
        }
    }, [token])

    useEffect(() => {
        console.log('data', data);
    }, [data])

    if (pageInit != 'complete') {
        return (
            <PageLoading />
        )
    }

    return (
        <Box>
            <NavHeader
                title={t("User's sessions")}
                breadcrumb={[
                    {
                        title: t("User's sessions")
                    }
                ]}
                backLink={"/admin/users/" + id}
            />
             <FilterTable filter={filter} setFilter={setFilter} />
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size='medium'
                    >
                        <EnhancedTableHead
                            rowCount={data?.length}
                        />
                        <TableBody>
                            {
                                data.sort((a, b) => dayjs(a.start_time).unix() - dayjs(b.start_time).unix()).map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    const { id, trainer, users, start_time, end_time, duration_minutes, sent_email, is_private, capacity, class_types } = row;
                                    const classInfo = row.class;
                                    const start = dayjs(start_time);
                                    const end = dayjs(end_time);
                                    const isActive = dayjs().isBefore(start);
                                    const isInBetween = dayjs().isBetween(start, end);
                                    const activeUser = Array.isArray(users) && users?.length ? users.filter((val) => val?.pivot?.status == "active"): [];
                                    const candeledUser = Array.isArray(users) && users?.length ? users.filter((val) => val?.pivot?.status == "canceled"): []
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row?.id}
                                            selected={isItemSelected}
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            <TableCell>
                                                <Link className='' to={"/admin/sessions/" + row?.id}>
                                                    <strong className="uppercase">{row?.class_types ? row.class_types : row?.class?.title}</strong>
                                                    <div className="flex gap-1 right-5 top-1">
                                                        { isInBetween ? (<span className='border border border-black px-1 text-xs uppercase !text-[8px]'>{t("Ongoing")}</span>) : !isActive ? <span className='border border border-black px-1 text-xs uppercase !text-[8px]'>{t("Passed")}</span> : ""}
                                                        {
                                                            is_private ? <span className='border border border-black px-1 text-xs uppercase !text-[8px]'>
                                                                {t("Private")}
                                                            </span> : ""
                                                        }
                                                        {
                                                            sent_email ? <span className='border bg-green-50 border-green-200 text-green-600 px-1 text-xs uppercase !text-[8px]'>
                                                                {t("Email")}
                                                            </span> : ""
                                                        }
                                                    </div>
                                                </Link>
                                            </TableCell>
                                            <TableCell>
                                                <Link to={"/admin/trainers/" + row?.trainer?.id}>{row?.trainer?.full_name}</Link>
                                            </TableCell>
                                            <TableCell>{row?.users?.length + "/" + row?.capacity}</TableCell>
                                            <TableCell>{start.format("HH:mm, DD/MM/YYYY")}</TableCell>
                                            <TableCell>{duration_minutes} {t("minutes")}</TableCell>
                                        </TableRow>
                                    );
                                })
                            } 
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    labelRowsPerPage={t("Rows per page:")}
                    rowsPerPageOptions={[12, 24, 36]}
                    component="div"
                    count={data?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    )
}

export default TableUserSessions
