import { Fragment } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FolderIcon from '@mui/icons-material/Folder';
import EditNoteIcon from '@mui/icons-material/EditNote';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import classNames from 'classnames';

import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { 
    IoHomeOutline, 
    IoImagesOutline, 
    IoCalendarOutline, 
    IoFolderOpenOutline, 
    IoCubeOutline, 
    IoGitMergeOutline, 
    IoPersonOutline, 
    IoCartOutline, 
    IoPeopleOutline, 
    IoStarOutline,
    IoPricetagsOutline, 
    IoLanguageOutline
} from "react-icons/io5";

const ListItems = () => {
    const { pathname } = useLocation();
    const { t } = useTranslation();
    return (
        <Fragment>
             <Link to="/">
                <ListItemButton className={classNames({"!bg-slate-700 !rounded": pathname == "/receptionist"})}>
                    <ListItemIcon sx={{ minWidth: 40 }}>
                        <IoHomeOutline size={22} color="#fff"/>
                    </ListItemIcon>
                    <ListItemText primary={t("Home")} />
                </ListItemButton>
            </Link>
            <Link to="/receptionist/sessions">
                <ListItemButton className={classNames({"!bg-slate-700 !rounded": pathname.startsWith("/receptionist/sessions/")})}>
                    <ListItemIcon sx={{ minWidth: 40 }}>
                        <IoCalendarOutline size={22} color="#fff"/>
                    </ListItemIcon>
                    <ListItemText primary={t("Sessions")} />
                </ListItemButton>
            </Link>
        </Fragment>
    )
};

export default ListItems;