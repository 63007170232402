import { Fragment, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { getToken, getDataUser } from '@/utils/slice/AuthSlice';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import Logo from "@/assets/logo-light.svg";
import ListItems from './listItems';
import PageLoading from '@/components/PageLoading';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import LogoutIcon from '@mui/icons-material/Logout';

import { LoadingButton } from '@mui/lab';
import { userLogout } from '@/utils/Auth';
import { useToasterContext } from '@/utils/ToasterContext';
import { Stack } from '@mui/material';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const drawerWidth = 240;

export default function DashboardLayout() {

    const { t } = useTranslation();
    const { token, user, status } = useSelector((state) => state.auth);
    const [mobileOpen, setMobileOpen] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { showMessage, changeLanguage, currentLanguage } = useToasterContext();

    const { pathname } = useLocation();
    
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        setMobileOpen(false)
    }, [pathname])

    const handleDrawerClose = () => {
        setIsClosing(true);
        setMobileOpen(false);
    };

    const handleDrawerTransitionEnd = () => {
        setIsClosing(false);
    };

    const handleDrawerToggle = () => {
        if (!isClosing) {
            setMobileOpen(!mobileOpen);
        }
    };

    async function handleUserLogout() {
        try {
            setIsLoading('logout');
            const response = await userLogout(token);
            if (response && !response.error) {
                showMessage("success", "You have successfully logged out!");
                navigate('/login');
                dispatch(deleteToken());
            } else {
                showMessage("error", "An error occurred during delete item!")
            }
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (!token) {
            dispatch(getToken());
        }
        dispatch(getDataUser(token));
    }, [token]);

    if (status !== 'complete') {
        return (
            <PageLoading />
        )
    }

    if (!token) {
        navigate('/login');
    }

    if (user && user.role !== 'receptionist') {
        navigate('/');
    }

    const DrawerContent = () => {
        return (
            <Box className='!bg-slate-900 h-full text-white'>
                <List
                    sx={{
                        p: 1,
                    }}
                    component="nav">
                    <ListItems />
                </List>
            </Box>
        )
    }

    return (
        <Fragment>
            <AppBar
                position="fixed"
                sx={{
                    background: "rgb(0 0 0 / 85%)",
                    backdropFilter: "blur(10px)",
                    zIndex: 9999
                }}>
                <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2, display: { sm: 'none' } }}
                >
                    <MenuIcon />
                </IconButton>
                <Toolbar
                    sx={{
                        display: 'flex',
                        flex: 1,
                        alignItems: 'center',
                        p: 0,
                        justifyContent: 'space-between',
                    }}>
                        <Link className='flex-1' to={"/admin"} >
                            <img src={Logo} width={120} alt="" />
                        </Link>
                        <Stack direction="row" alignItems="center" spacing={3}>
                            <div>
                                <div className='bg-slate-700 rounded-full flex gap-1 items-center px-1 py-1 h-auto' spacing={1}>
                                    <button onClick={() => changeLanguage("en")} className={classNames("uppercase text-[16px] leading-[16px] rounded-full !px-3 !py-1", {"bg-black": currentLanguage == 'en'})}>
                                        {t("EN")}
                                    </button>
                                    <button onClick={() => changeLanguage("vi")} className={classNames("uppercase text-[16px] leading-[16px] rounded-full !px-3 !py-1", {"bg-black": currentLanguage == 'vi'})}>
                                        {t("VN")}
                                    </button>
                                </div>
                            </div>
                            <IconButton 
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                loading={isLoading} 
                                onClick={handleUserLogout}>
                                <LogoutIcon color='#fff'/>
                            </IconButton>
                        </Stack>
                    </Toolbar>
                </Toolbar>
            </AppBar>
        <Box id="dashboard" sx={{ display: 'flex' }}>
        <Box
            component="nav"
            id="dashboard-nav"
            sx={{ 
                width: { sm: drawerWidth }, 
                flexShrink: { sm: 0 },
            }}
            aria-label="mailbox folders">
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onTransitionEnd={handleDrawerTransitionEnd}
                    onClose={handleDrawerClose}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        borderRight: "unset !important",
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}>
                    <DrawerContent />
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        borderRight: "unset !important",
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open>
                    <DrawerContent />
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
                <Box sx={{px: {
                    lg: 4,
                    xs: 0
                }, pt: 10}}>
                    <Outlet />
                </Box>
            </Box>
        </Box>
    </Fragment>
    );
}