import { useState, Fragment } from 'react';
import { alpha } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import { Avatar, Button, Paper } from '@mui/material';
import { getInitials } from '@/utils/Hooks';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';


const headCells = [
    {
        id: 'trainer',
        numeric: false,
        disablePadding: false,
        label: 'Instructor',
    },
    {
        id: 'latest-seen',
        numeric: false,
        disablePadding: false,
        label: 'Sessions',
    },
    {
        id: 'Total hours',
        numeric: false,
        disablePadding: false,
        label: 'Total hours',
    },
    {
        id: 'action',
        numeric: false,
        disablePadding: false,
        label: 'Action',
    },
];

const APP_URL = process.env.REACT_APP_APP_URL;

function EnhancedTableHead(props) {
    const { order, orderBy } = props;
    const { t } = useTranslation();
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <p sx={{ fontWeight: 600 }}>
                            {t(headCell.label)}
                        </p>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

function EnhancedTableToolbar(props) {
    const { numSelected } = props;
    const { t } = useTranslation();
    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <>
                    <p
                        sx={{ flex: '1 1 100%' }}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {numSelected} {t("selected")}
                    </p>
                    <Tooltip title={t("Delete")}>
                        <IconButton>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </>
            ) : ""
            }
        </Toolbar>
    );
}

export default function EnhancedTable({ data }) {

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);
    const [deleteItem, setDeleteItem] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [pageStatus, setPageStatus] = useState('idle');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = data.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;


    const Row = ({ rowData }) => {
        console.log('rowData', rowData);
        const {
            id,
            full_name,
            total_hours,
            thumbnail_url,
            total_sessions,
            total_bonus,
            total_bonus_hours
        } = rowData;

        const { t } = useTranslation();

        const isItemSelected = isSelected(id);

        return (
            <Fragment>
                <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={id}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}>
                    <TableCell>
                        <Stack direction="row" spacing={2} alignItems="center">
                            <Avatar 
                                sx={{
                                    bgcolor: "#000",
                                }} 
                                src={APP_URL + "/" + thumbnail_url}
                                children={getInitials(full_name)}
                            />
                            <p sx={{fontWeight: 600}}>
                                {full_name}
                            </p>
                        </Stack>
                    </TableCell>
                    <TableCell>
                        <p>
                            {total_sessions}
                        </p>
                    </TableCell>
                    <TableCell sx={{fontWeight: 600}}>
                        <p>
                            {total_hours}
                        </p>
                    </TableCell>
                    <TableCell>
                        <Link to={"/admin/trainers/" + id + "/analytics"}>
                            <Button size='small' variant='contained' sx={{borderRadius: 0}}>
                                {t("Detail")}
                            </Button>
                        </Link>
                    </TableCell>
                </TableRow>
            </Fragment>
        )
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Paper variant='plain' sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 480, maxWidth: "calc(100vw - 54px)" }}>
                    <Table
                        stickyHeader aria-label="sticky table"
                        size='medium'>
                        <EnhancedTableHead
                            numSelected={selected.length}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={data?.length}
                        />
                        <TableBody>
                            {
                                Array.isArray(data) && data.length ? data.sort((a, b) => b.total_hours - a.total_hours).map((row) => {
                                        return (
                                            <Row key={row.id} rowData={row} />
                                        );
                                    }) : ""
                            }
                        </TableBody>
                </Table>
                {
                    selected.length ? (
                        <EnhancedTableToolbar
                            numSelected={selected.length}
                            isLoading={deleteLoading}
                            onSubmit={() => handleDeleteMultipeData(selected)}
                        />
                    ) : ""
                }
            </TableContainer>
            </Paper>
        </Box>
    );
}