
import { useState, useEffect, Fragment } from 'react';
import { findOneSubscription, updateSubscription } from '@/utils/Subscriptions';
import { useSelector } from 'react-redux';
import { useToasterContext } from '@/utils/ToasterContext'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'

import Box from '@mui/material/Box';
import NavHeader from '@/components/NavHeader';
import validate from 'validate.js'
import PageLoading from '@/components/PageLoading'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import dayjs from 'dayjs'

import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { findManySessions } from '@/utils/Sessions';
import FilterSubscriptionSession from '@/components/FilterSubscriptionSession';

const headCells = [
    {
        id: 'transaction_ref',
        numeric: false,
        disablePadding: false,
        label: 'Class',
    },
    {
        id: 'transaction_type',
        numeric: false,
        disablePadding: false,
        label: 'Trainer',
    },
    {
        id: 'transaction_amount',
        numeric: false,
        disablePadding: false,
        label: 'Start in',
    },
    {
        id: 'duration',
        numeric: false,
        disablePadding: false,
        label: 'Duration',
    },
    {
        id: 'no_show',
        numeric: false,
        disablePadding: false,
        label: 'No show',
    },
    {
        id: 'transaction_status',
        numeric: false,
        disablePadding: false,
        label: 'Checked in',
    },
    {
        id: 'created_at',
        numeric: false,
        disablePadding: false,
        label: 'Checked out',
    },
    {
        id: 'action',
        numeric: false,
        disablePadding: false,
        label: 'Action',
    }
];

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount } = props;
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <Typography sx={{ fontWeight: 600 }}>
                            {headCell.label}
                        </Typography>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const SubscriptionDetailed = () => {

    const { id } = useParams();
    const { t } = useTranslation();
    const { token } = useSelector((state) => state.auth);
    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const [isLoading, setIsLoading] = useState(false);
    const [formError, setFormError] = useState(false);
    const [user, setUser] = useState(null);
    const [membership, setMembership] = useState(null);
    const [isTriggerActive, setIsTriggerActive] = useState(0);
    const [pageInit, setPageInit] = useState(false);
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [filter, setFilter] = useState({
        subscription_id: id
    });

    async function handleFindData() {
        try {
            startProgress();
            setSearchParams(filter);
            const response = await findManySessions(filter);
            if (response && !response.error) {
                const { data } = response;
                if (data) {
                    setData(data)
                }
            }
        } catch (error) {

        } finally {
            setPageInit("complete");
            completeProgress();
        }
    }

    async function handleUpdate(token, id, data) {
        try {
            setIsLoading(true);
            startProgress();
            const notify = data?.sent_notify;
            const response = await updateSubscription(token, id, data);
            if (response && !response.error) {
                const { data } = response;
                if (data?.id) {
                    showMessage('success', 'Data updated successfully!');
                    if(notify){
                        showMessage('success', "Registration information has been sent to the user's email");
                    }
                    navigate("/admin/subscriptions/" + data.id);
                } else {
                    showMessage('error', 'An error occurred, please try again later!');
                }
            }
        } catch (error) {
            showMessage('error', 'An error occurred, please try again later!');
        } finally {
            setIsLoading(false);
            completeProgress(true);
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const notify = data.get('sent_notify');

        let dataForm = {
            user_id: user?.id,
            membership_id: membership?.id,
            max_sessions: data.get('max_sessions'),
            sessions_used: data.get('sessions_used'),
            status: data.get('status'),
            trigger_active: isTriggerActive ? 1 : 0,
            sent_notify: notify == 'on' ? 1 : 0
        }

        if(!isTriggerActive) {
            dataForm = {
                ...dataForm,
                start_date: dayjs(data.get('start_date'), "DD/MM/YYYY").format("YYYY-MM-DD"),
                end_date: dayjs(data.get('end_date'), "DD/MM/YYYY").format("YYYY-MM-DD"),
            }
        }

        const error = validate(dataForm, constraints);

        if (!error) {
            handleUpdate(token, id, dataForm)
        } else {
            console.log('error', error);
            showMessage('error', "One or more input fields are invalid!");
        }

        setFormError(error);
    }

    // Custom validator to ensure end_time is after start_time
    validate.validators.timeAfter = function (value, options, key, attributes) {
        const startDate = dayjs(attributes.start_date, 'YYYY-MM-DD');
        const endDate = dayjs(value, 'YYYY-MM-DD');;
        if (endDate.isBefore(startDate)) {
            return options.message || "must be after start time";
        }
    };

    let constraints = {
        user_id: {
            presence: true,
        },
        membership_id: {
            presence: true,
        },
        max_sessions: {
            presence: true,
        },
        status: {
            presence: true,
        }
    };

    if(!isTriggerActive){
        constraints = {
            ...constraints,
            start_date: {
                presence: { allowEmpty: false, message: "is required" },
            },
            end_date: {
                presence: { allowEmpty: false, message: "is required" },
                timeAfter: {
                    message: "must be after start time"
                }
            },
        }
    }

    useEffect(() => {
        handleFindData(filter);
    }, [filter])

    useEffect(() => {
        console.log('data', data);
    }, [data])


    const Row = ({ rowData }) => {

        const {
            id,
            class_types,
            transaction_ref,
            trainer,
            start_time,
            end_time,
            duration_minutes,
            no_show,
            check_out_time,
            users,
            session
        } = rowData;

        console.log('rowData', rowData);

        const academicClass = rowData?.class;
        const [open, setOpen] = useState(false);
        const pivot = users[0]?.pivot;

        console.log('pivot', pivot);

        return (
            <Fragment>
                <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={transaction_ref}
                    onClick={() => setOpen(!open)}
                    sx={{ cursor: 'pointer' }}>
                    <TableCell>
                        <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center">    
                            <Link to={"/admin/sessions/" + id}>
                                <strong className="uppercase underline">
                                    {class_types ? class_types : academicClass?.title} 
                                </strong>
                            </Link>
                        </Stack>
                    </TableCell>
                    <TableCell>
                       <Link to={`/admin/trainers/${trainer?.id}`}>
                            <Typography className='underline'>
                                {trainer?.full_name}
                            </Typography>
                       </Link>
                    </TableCell>
                    <TableCell>
                        <Typography>
                            { start_time ? dayjs(start_time).format("HH:mm, DD/MM/YYYY") : "-"}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography>
                            {duration_minutes} minutes
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography>
                            {pivot?.no_show ? t("No show") : "-"}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography>
                            {pivot?.check_in_time ? dayjs(pivot?.check_in_time).format("HH:mm, DD/MM/YYYY") : "-"}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography>
                            {pivot?.check_out_time ? dayjs(pivot?.check_out_time).format("HH:mm, DD/MM/YYYY") : "-"}
                        </Typography>
                    </TableCell>
                    <TableCell sx={{ width: 120}}>
                        <Link to={"/admin/sessions/" + id}>
                            <Button variant='contained'>
                                {t('View')}
                            </Button>
                        </Link>
                    </TableCell>
                </TableRow>
            </Fragment>
        )
    }


    if (pageInit != 'complete') {
        return (
            <PageLoading />
        );
    }

    return (
        <Box sx={{ width: '100%' }}>
            <NavHeader
                title="Subscription detailed"
                breadcrumb={[
                    {
                        title: 'Subscriptions',
                        link: '/admin/subscriptions/' + id
                    },
                    {
                        title: 'Subscription detailed'
                    }
                ]}
                backLink={'/admin/subscriptions/' + id}
            />
           <FilterSubscriptionSession filter={filter} setFilter={setFilter}/>
           <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', overflow: 'hidden', mb: 3, borderRadius: 0 }}>
                <TableContainer sx={{ maxHeight: 640, maxWidth: "calc(100vw - 54px)" }}>
                    <Table
                        stickyHeader aria-label="sticky table"
                        size='medium'>
                            <EnhancedTableHead
                                rowCount={data?.length}
                            />
                            <TableBody>
                                {data.map((row) => {
                                    return (
                                        <Row key={row.id} rowData={row} />
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Box>
        </Box>
    )
}

export default SubscriptionDetailed
