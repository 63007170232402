import React, { Fragment, useEffect, useState } from 'react'
import { Grid, Typography, Box, Button, IconButton } from '@mui/material'
import { Link } from 'react-router-dom';
import { deleteSession } from '@/utils/Sessions';
import { useSelector } from 'react-redux';
import { useToasterContext } from '@/utils/ToasterContext';

import AddIcon from '@mui/icons-material/Add';
import dayjs from 'dayjs';
import ConfirmDelete from '@/components/ConfirmDelete';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import classNames from 'classnames';

import isBetween from 'dayjs/plugin/isBetween';
import { useTranslation } from 'react-i18next';
// Kích hoạt plugin
dayjs.extend(isBetween);

const WeekView = ({ data, step, reload }) => {

    const { t } = useTranslation();
    const [weekDays, setWeekDays] = useState([]);
    const [weekData, setWeekData] = useState({
        sun: [], mon: [], tue: [], wed: [], thu: [], fri: [], sat: []
    });

    const [deleteItem, setDeleteItem] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const { token } = useSelector((state) => state.auth);
    const { showMessage } = useToasterContext();

    async function handleDeleteData(id) {
        try {
            setDeleteLoading(true);
            const response = await deleteSession(token, id);
            if (response && !response.error) {
                const { id } = response;
                if (id) {
                    showMessage("success", t("Delete item successully!"));
                    reload();
                }
            } else {
                showMessage("error", t("An error occurred during delete item!"))
            }
        } catch (error) {
            showMessage("error", t("An error occurred during delete item!"))
        } finally {
            setDeleteLoading(false);
            setDeleteItem(false);
        }
    }

    useEffect(() => {
        const startOfWeek = dayjs().startOf('week').add(step, 'week'); 
        setWeekDays(Array.from({ length: 7 }, (_, i) => 
            startOfWeek.add(i, 'day').format('DD/MM')
        ));
    }, [step]);



    useEffect(() => {
        const updatedWeekData = {
            sun: [], mon: [], tue: [], wed: [], thu: [], fri: [], sat: []
        };

        data?.forEach(item => {
            const dayKey = dayjs(item.start_time).format('ddd').toLowerCase();
            updatedWeekData[dayKey]?.push(item);
        });

        setWeekData(updatedWeekData);
    }, [data]);

    const daysOfWeek = {
        sun: t("Sunday"),
        mon: t("Monday"),
        tue: t("Tuesday"),
        wed: t("Wednesday"),
        thu: t("Thursday"),
        fri: t("Friday"),
        sat: t("Saturday"),
    };

    return (
       <Fragment>
        <Box 
            className='overflow-x-auto' 
            sx={{ 
            minHeight: 480,
            maxHeight: {
                lg: "unset",
                xs: 480
            }, maxWidth: "calc(100vw - 54px)" }}>
            <Grid sx={{ minWidth: 1280 }} container spacing={1}>
                {Object.keys(daysOfWeek).map((dayKey, index) => {

                    const currentDayString = dayjs().format('DD/MM');
                    const currentDay = dayjs(currentDayString, 'DD/MM');
                    const compareDay = dayjs(weekDays[index], 'DD/MM');
                    let compare = false;

                    if (compareDay.isBefore(currentDay)) {
                        compare = "before";
                    } else if (compareDay.isAfter(currentDay)) {
                        compare = "after";
                    } else {
                        compare = "same";
                    }

                    return (
                        <Grid
                            key={dayKey}
                            sx={{ flex: 1 }}
                            item>
                            <Box
                                className={{
                                    before: "shadow bg-white",
                                    same: "shadow bg-white",
                                    after: "shadow bg-white"
                                }[compare]}>
                                <Box className={{
                                    before: "bg-neutral-300 sticky top-0 z-10 text-neutral-900 py-3",
                                    same: "bg-blue-700 sticky top-0 z-10 text-white py-3",
                                    after: "bg-neutral-900 sticky top-0 z-10 text-white py-3"
                                }[compare]}>
                                    <Typography
                                        sx={{
                                            fontWeight: 700,
                                            textTransform: "uppercase",
                                            textAlign: "center",
                                            fontSize: 14
                                        }}>
                                        {daysOfWeek[dayKey]} {weekDays[index]}
                                    </Typography>
                                    <p className='text-xs text-center'>{weekData[dayKey]?.length > 2 ? `${weekData[dayKey].length } ${t("sessions")}`: `${weekData[dayKey].length } ${t("session")}`}</p>
                                </Box>
                                {
                                    Array.isArray(weekData[dayKey]) && weekData[dayKey].length ?
                                        weekData[dayKey].sort((a, b) => dayjs(a.start_time).unix() - dayjs(b.start_time).unix()).map((val) => {
                                            const { id, trainer, users, start_time, end_time, duration_minutes, sent_email, is_private, capacity, class_types } = val;
                                            const classInfo = val.class;
                                            const start = dayjs(start_time);
                                            const end = dayjs(end_time);
                                            const isActive = dayjs().isBefore(start);
                                            const isInBetween = dayjs().isBetween(start, end);
                                            const activeUser = Array.isArray(users) && users?.length ? users.filter((val) => val?.pivot?.status == "active"): [];
                                            const candeledUser = Array.isArray(users) && users?.length ? users.filter((val) => val?.pivot?.status == "canceled"): []
                                            return (
                                                <div
                                                    className={classNames({
                                                        "bg-white relative hover:bg-neutral-100 cursor-pointer border py-2 relative group": true,
                                                        "border-neutral-200 text-neutral-900": compare === 'before',
                                                        "border-neutral-100": compare === 'after'
                                                    })}
                                                    sx={{ mb: 1, fontSize: 12 }}
                                                    key={id}>
                                                        {/* <IconButton 
                                                            onClick={() => setDeleteItem(id)} 
                                                            className='!absolute opacity-0 group-hover:opacity-100 top-1 right-1'>
                                                            <DeleteOutlineIcon />
                                                        </IconButton> */}
                                                    <Link
                                                        to={"/receptionist/sessions/" + id + "?step=" + step}
                                                        size="small">
                                                        <Box sx={{ p: 1 }}>
                                                            <div className="absolute flex gap-1 right-5 top-1">
                                                                { isInBetween ? (<span className='border border border-black px-1 text-xs uppercase text-[8px]'>{t("Ongoing")}</span>) : !isActive ? <span className='border border border-black px-1 text-xs uppercase text-[8px]'>{t("Passed")}</span> : ""}
                                                                {
                                                                    is_private ? <span className='border border border-black px-1 text-xs uppercase text-[8px]'>
                                                                        {t("Private")}
                                                                    </span> : ""
                                                                }
                                                                {
                                                                    sent_email ? <span className='border bg-green-50 border-green-200 text-green-600 px-1 text-xs uppercase text-[8px]'>
                                                                        {t("Email")}
                                                                    </span> : ""
                                                                }
                                                            </div>
                                                            {
                                                                classInfo ?
                                                                    <Typography  sx={{ fontSize: 16 }} className='font-bold  uppercase'>
                                                                        <strong>{class_types ? class_types : classInfo?.title}</strong>
                                                                    </Typography>
                                                                    : ""
                                                            }
                                                            {
                                                                trainer ?
                                                                    <Typography sx={{ fontSize: 13 }}>
                                                                        {t("with")} <strong>{trainer?.full_name}</strong>
                                                                    </Typography>
                                                                    : ""
                                                            }
                                                            {
                                                                users ?
                                                                    <Typography sx={{ fontSize: 13 }}>
                                                                        {t("Capacity")}: {activeUser.length} / {capacity}
                                                                    </Typography>
                                                                    : ""
                                                            }
                                                            {
                                                                candeledUser?.length ? 
                                                                    <Typography sx={{ fontSize: 13 }}>
                                                                        {candeledUser.length} {t("canceled")}
                                                                    </Typography> : ""
                                                            }
                                                            {
                                                                start ?
                                                                    <Typography sx={{ fontSize: 13 }}>
                                                                        {start.format('HH:mm')} - {end.format('HH:mm')}
                                                                    </Typography>
                                                                    : ""
                                                            }
                                                            {
                                                                duration_minutes ?
                                                                    <Typography sx={{ fontSize: 13 }}>
                                                                        {t("Duration")}: <strong>{duration_minutes} {t("minutes")}</strong>
                                                                    </Typography>
                                                                    : ""
                                                            }
                                                        </Box>
                                                    </Link>
                                                </div>
                                            )
                                        })
                                        : (
                                            <Box className="py-3">
                                                <Typography sx={{ fontSize: 12, textAlign: "center" }}>
                                                    {t("Empty")}
                                                </Typography>
                                            </Box>
                                        )
                                }
                            </Box>
                            {/* <Box
                                className=""
                                sx={{ pt: 3, textAlign: "center" }}>
                                <Button
                                    startIcon={<AddIcon />}
                                    block
                                    className='text-center'
                                    as={Link}
                                    variant='plain'
                                    to={`/receptionist/sessions/create?schedule=${weekDays[index]}&step=${step}`} >
                                    {t("add session")}
                                </Button>
                            </Box> */}
                        </Grid>
                    )
                })}
            </Grid>            
        </Box>
        <ConfirmDelete
            open={deleteItem ? true : false}
            setOpen={setDeleteItem}
            isLoading={deleteLoading}
            onSubmit={() => handleDeleteData(deleteItem)}
        />
       </Fragment>
    )
}

export default WeekView