import { Box, Button, FormControlLabel, Grid, Switch } from '@mui/material'
import { useState, useEffect } from 'react'
import { useToasterContext } from '@/utils/ToasterContext'

import NavHeader from '@/components/NavHeader'
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput';
import validate from 'validate.js'

import { LoadingButton } from '@mui/lab'
import { findOneUser, updateUser } from '@/utils/Users'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TableSubscription from "../Orders/Components/TableSubscription";
import PageLoading from '@/components/PageLoading'
import dayjs from 'dayjs';
import PostPicker from '@/components/PostPicker';
import { findManyTrainers } from '@/utils/Trainers';

const CreateClass = () => {

    const { t } = useTranslation();
    const { token } = useSelector((state) => state.auth);
    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const { id } = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [formError, setFormError] = useState(false);
    const [defaultValue, setDefaultValue] = useState(false);
    const [changePassword, setChangePassword] = useState(false);
    const [subscriptions, setSubscription] = useState(false);
    const [role, setRole] = useState(false);
    const [trainer, setTrainer] = useState(false);
    const navigate = useNavigate();

    async function handleFindOne() {
        try {
            const response = await findOneUser(token, id);
            if (response && !response.error) {
                const {
                    user_information,
                    display_name,
                    email,
                    role,
                    subscriptions,
                    trainer
                } = response;

                setSubscription(subscriptions);
                setRole(role);
                setTrainer(trainer);
                setDefaultValue({
                    display_name,
                    email,
                    role,
                    first_name: user_information?.first_name,
                    last_name: user_information?.last_name,
                    date_of_birth: user_information?.date_of_birth,
                    password: ""
                });
            }
        } catch (error) {

        }
    }

    async function handleUpdate(data) {
        try {
            setIsLoading(true);
            startProgress();
            const response = await updateUser(token, id, data);
            if (response && !response.error) {
                const { data } = response;
                showMessage('success', t('Data updated successfully!'));
                if (data?.id) {
                    navigate("/admin/users/" + data.id);
                }
            } else {
                showMessage('error', response?.data?.message || t('An error occurred, please try again later'));
            }
        } catch (error) {
            console.error('An error occurred:', error);
            showMessage('error', t('An error occurred, please try again later'));
        } finally {
            setIsLoading(false);
            completeProgress(true);
        }
    }

    function handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let dataForm = {
            first_name: data.get("first_name"),
            last_name: data.get("last_name"),
            date_of_birth: dayjs(data.get('date_of_birth'), 'DD/MM/YYYY').format("YYYY-MM-DD"),
            email: data.get("email"),
            role: data.get("role"),
            chane_password: changePassword,
            password: data.get("password")
        }
        if(role == 'trainer'){
            dataForm = {
                ...dataForm,
                trainer_id: trainer?.id
            }
        }
        const error = validate(dataForm, constraints);
        if (!error) {
            handleUpdate(dataForm)
        }
        setFormError(error);
    }

    var roles = [
        { "label": "Administrator", "value": "admin" },
        { "label": "Trainer", "value": "trainer" },
        { "label": "Receptionist", "value": "receptionist" },
        { "label": "User", "value": "user" },
    ];

    useEffect(() => {
        if(token){
            handleFindOne();
        }
    }, [token])

    let constraints = {
        first_name: {
            presence: { allowEmpty: false },
        },
        last_name: {
            presence: { allowEmpty: false },
        },
        email: {
            email: true
        },
        role: {
            presence: { allowEmpty: false }
        }
    };

    if(changePassword){
        constraints = {...constraints,         
        password: {
            presence: { allowEmpty: false }
        }}
    }

    if (!defaultValue) {
        return (
            <PageLoading />
        );
    }

    return (
            <Box sx={{ width: '100%' }}>
                <NavHeader
                    title={t("Update user")}
                    breadcrumb={[
                        {
                            title: t('Update User')
                        }
                    ]}
                    endAction={(
                        <Link to={"/admin/users/" + id + "/sessions"} >
                            <Button
                                variant='contained'
                                color='primary'>
                                {t("User Sessions")}
                            </Button>
                        </Link>
                    )}
                    backLink="/admin/users"
                />
                <Box  component="form" onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={8}>
                        <Grid container spacing={3}>
                                <Grid item xs={12} lg={6}>
                                    <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.first_name} required>
                                        <FormLabel>{t("First name")}</FormLabel>
                                        <TextField
                                            name="first_name"
                                            error={formError?.first_name}
                                            defaultValue={defaultValue?.first_name}
                                        />
                                        {formError?.first_name ? <FormHelperText>{formError?.first_name[0]}</FormHelperText> : ""}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.last_name} required>
                                        <FormLabel>{t("Last name")}</FormLabel>
                                        <TextField
                                            name="last_name"
                                            error={formError?.last_name}
                                            defaultValue={defaultValue?.last_name}
                                        />
                                        {formError?.last_name ? <FormHelperText>{formError?.last_name[0]}</FormHelperText> : ""}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <FormControl sx={{ width: "100%", mb: 1 }} error={formError?.date_of_birth} required>
                            <FormLabel>{t("Date of birth")}</FormLabel>
                                <DatePicker
                                    name="date_of_birth"
                                    required
                                    value={defaultValue?.date_of_birth ? dayjs(defaultValue?.date_of_birth): dayjs()}
                                />
                                {formError?.date_of_birth ? <FormHelperText>{formError?.date_of_birth[0]}</FormHelperText> : ""}
                            </FormControl>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.email} required>
                                <FormLabel>Email</FormLabel>
                                <TextField
                                    name="email"
                                    type="email"
                                    error={formError?.email}
                                    defaultValue={defaultValue?.email}
                                />
                                {formError?.email ? <FormHelperText>{formError?.email[0]}</FormHelperText> : ""}
                            </FormControl>
                            <FormControl sx={{ mb: 3, width: "100%" }} error={formError?.role} required>
                                <FormLabel id="demo-multiple-name-label">User role</FormLabel>
                                <Select
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    name="role"
                                    defaultValue={defaultValue?.role}
                                    input={<OutlinedInput label="" />}>
                                    {roles.map((val, index) => (
                                        <MenuItem
                                            key={index}
                                            value={val.value}>
                                            {val.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {formError?.role ? <FormHelperText>{formError?.role[0]}</FormHelperText> : ""}
                            </FormControl>
                            {
                                role == 'trainer' ? (
                                    <Box sx={{ mb: 1 }}>
                                        <PostPicker
                                            label={t("Select intructor")}
                                            name="user"
                                            defaultData={trainer}
                                            values={[trainer]}
                                            getApi={findManyTrainers}
                                            onChange={(value) => setTrainer((value))}
                                            error={formError?.trainer_id}
                                            optionKey="full_name"
                                            previewKey="email"
                                        />
                                    </Box>
                                ): ''
                            }
                            <FormControl sx={{ width: "100%", mb: 3 }}>
                                <FormControlLabel
                                    control={<Switch />}
                                    checked={changePassword}
                                    onChange={() => setChangePassword(!changePassword)}
                                    label="Change user's password" />
                            </FormControl>
                            {
                                changePassword ? (
                                    <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.password}>
                                        <FormLabel>User password</FormLabel>
                                        <TextField
                                            name="password"
                                            type="password"
                                            error={formError?.password}
                                        />
                                        {formError?.password ? <FormHelperText>{formError?.password[0]}</FormHelperText> : ""}
                                    </FormControl>
                                ) : ""
                            }
                            <FormControl>
                                <LoadingButton
                                    variant='contained'
                                    color='primary'
                                    size='lg'
                                    type='submit'
                                    loading={isLoading}
                                >
                                    Update user
                                </LoadingButton>
                            </FormControl>
                            <Box sx={{mt: 3}}>
                                <h3 className='uppercase font-bold mb-3'>Subscriptions</h3>
                                <TableSubscription defaultData={subscriptions}/>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
    )
}

export default CreateClass
