import { useState, useEffect } from 'react';
import { alpha } from '@mui/material/styles';
import { Link, useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ConfirmDelete from '@/components/ConfirmDelete';
import NavHeader from '@/components/NavHeader';
import ActionCell from '@/components/ActionCell';
import FilterTable from '@/components/FilterTable';
import PageLoading from '@/components/PageLoading';
import dayjs from 'dayjs';

import { useToasterContext } from '@/utils/ToasterContext';
import { useSelector } from 'react-redux';
import { deleteSubscription, deleteSubscriptions, findManySubscriptions, sendExtendEmail } from '@/utils/Subscriptions';
import { Avatar, ListItemIcon, ListItemText, MenuItem, Stack } from '@mui/material';
import { getInitials } from '@/utils/Hooks';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import DateCell from '@/components/DateCell';
import FilterSubscription from '@/components/FilterSubscription';
import { MailOutlineOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

const headCells = [
    {
        id: 'id',
        label: 'ID',
    },
    {
        id: 'user',
        disablePadding: false,
        label: 'User',
    },
    {
        id: 'membership',
        disablePadding: false,
        label: 'Membership',
    },
    {
        id: 'start_date',
        disablePadding: false,
        label: 'Start date',
        width: 120
    },
    {
        id: 'end_date',
        disablePadding: false,
        label: 'End date',
        width: 120
    },
    {
        id: 'max_sessions',
        disablePadding: false,
        label: 'Session used',
        width: 120
    },
    {
        id: 'remaining',
        disablePadding: false,
        label: 'Remaining',
    },
    {
        id: 'status',
        disablePadding: false,
        label: 'Status',
    },
    {
        id: 'created_at',
        label: 'Created at',
    },
    {
        id: 'action',
        disablePadding: false,
        label: 'Action',
    },
];

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, width } = props;
    const { t } = useTranslation();
    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        width={width}
                    >
                        <p>
                            {t(headCell.label)}
                        </p>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}


function EnhancedTableToolbar(props) {
    const { numSelected, onSubmit } = props;
    const { t } = useTranslation();
    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <>
                    <p
                        sx={{ flex: '1 1 100%' }}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {numSelected} {t("selected")}
                    </p>
                    <Tooltip title="Delete">
                        <IconButton onClick={onSubmit}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </>
            ) : ""
            }
        </Toolbar>
    );
}

export default function EnhancedTable() {

    const { token } = useSelector((state) => state.auth);
    const { t } = useTranslation();
    const { showMessage, startProgress, completeProgress } = useToasterContext()
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);
    const [data, setData] = useState([]);
    const [deleteItem, setDeleteItem] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [pageStatus, setPageStatus] = useState('idle');
    const [searchParams, setSearchParams] = useSearchParams();
    const [sendEmail, setSendEmail] = useState(false);

    const [total, setTotal] = useState(0);
    const [filter, setFilter] = useState({
        search: "",
        order: "desc",
        paged: 1,
        per_page: 12,
        start_date: null,
        end_date: null
    })

    const handleFindManyData = async (filter) => {
        try {
            startProgress();
            const response = await findManySubscriptions(filter);
            setSearchParams(filter);
            if (response && !response.error) {
                const { data, meta } = response;
                if (Array.isArray(data)) {
                    setData(data);
                    setTotal(meta?.total);
                } else {
                    setData([]);
                }
            }
        } catch (error) {
            console.error("error: ", error);
        } finally {
            setPageStatus("complete");
            completeProgress()
        }
    }

    async function handleSendExtendEmail(id) {
        try {
            startProgress();
            setDeleteLoading(true);
            setSendEmail(id);
            const response = await sendExtendEmail(token, id);
            if (response && !response.error) {
                const { data } = response;
                if (data?.id) {
                    handleFindManyData(filter);
                    showMessage("success", t("Email sent successully!"))
                }
            } else {
                showMessage("error", t("An error occurred during sent email!"))
            }
        } catch (error) {
            showMessage("error", t("An error occurred during sent email!"))
        } finally {
            completeProgress();
            setSendEmail(false);
        }
    }

    async function handleDeleteData(id) {
        try {
            setDeleteLoading(true);
            const response = await deleteSubscription(token, id);
            if (response && !response.error) {
                const { id } = response;
                if (id) {
                    handleFindManyData(filter);
                    showMessage("success", t("Delete item successully!"))
                }
            } else {
                showMessage("error", t("An error occurred during delete item!"))
            }
        } catch (error) {
            showMessage("error", t("An error occurred during delete item!"))
        } finally {
            setDeleteLoading(false);
            setDeleteItem(false);
        }
    }

    async function handleDeleteMultipeData(selected) {
        try {
            setDeleteLoading(true);
            const response = await deleteSubscriptions(token, selected);
            if (response && !response.error) {
                handleFindManyData(filter);
                showMessage("success", t("Delete item successully!"))
            } else {
                showMessage("error", t("An error occurred during delete item!"))
            }
        } catch (error) {
            showMessage("error", t("An error occurred during delete item!"))
        } finally {
            setDeleteLoading(false);
            setDeleteItem(false);
            setSelected([]);
        }
    }

    useEffect(() => {
        if(token){
            const initFilter = {
                search: searchParams.get('search'),
                order: searchParams.get('order'),
                start_date: searchParams.get('start_date'),
                end_date: searchParams.get('end_date'),
                paged: searchParams.get('paged'),
                per_page: searchParams.get('per_page'),
            };
            let filterParams = {};
            for (const key in initFilter) {
                if (Object.hasOwnProperty.call(initFilter, key)) {
                    const element = initFilter[key];
                    if (element != null) {
                        filterParams[key] = element;
                    }
                }
            }
            setFilter((filter) => ({
                ...filter,
                ...filterParams
            }));
            handleFindManyData(filter);
        }
    }, [token])

    useEffect(() => {
        if (pageStatus == 'complete') {
            let filterParams = {};
            for (const key in filter) {
                if (Object.hasOwnProperty.call(filter, key)) {
                    const element = filter[key];
                    if (element) {
                        filterParams[key] = element;
                    }
                }
            }
            handleFindManyData(filterParams);
        }
    }, [filter])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = data.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setFilter({
            ...filter,
            paged: newPage ? (newPage + 1) : 1
        });
    };

    const handleChangeRowsPerPage = (event) => {
        setFilter({
            ...filter,
            per_page: parseInt(event.target.value),
            paged: 1
        });
    };
    const isSelected = (id) => selected.indexOf(id) !== -1;

    if (pageStatus != 'complete') {
        return (
            <PageLoading />
        )
    }

    const StatusLabel = ({ end_date }) => {

        const getStatusLabelAndColorClass = (endDate) => {
            const today = dayjs().startOf('day');
            const end = dayjs(endDate, "YYYY-MM-DD");
            const daysLeft = end.diff(today, 'day');
          
            let label = '';
            let colorClass = ''; // Mặc định màu xám
            
            if (daysLeft < 0) {
              label = 'Expired';
              colorClass = 'text-xs ml-1 block text-red-500'; // Màu đỏ cho đã hết hạn
            } else if (daysLeft == 1) {
              label = '1 day left';
              colorClass = 'text-xs ml-1 block text-orange-600'; // Màu cam cho 1 ngày còn lại
            } else if (daysLeft == 2) {
              label = '2 days left';
              colorClass = 'text-xs ml-1 block text-orange-500'; // Màu cam cho 2 ngày còn lại
            } else if (daysLeft == 3) {
              label = '3 days left';
              colorClass = 'text-xs ml-1 block text-orange-400'; // Màu cam cho 3 ngày còn lại
            } 
          
            return { label, colorClass };
         };

        const { label, colorClass } = getStatusLabelAndColorClass(end_date);
      
        return (
          <p>
            {dayjs(end_date, "YYYY-MM-DD").format("DD/MM/YYYY")}
            {label ? <span className={`${colorClass}`}>({label})</span> : ""}
          </p>
        );
    };

    const Row = ({ rowData }) => {
        const { id, user, membership, status, max_sessions, sessions_used, start_date, end_date, created_at } = rowData
        const isItemSelected = isSelected(id);
        const labelId = `enhanced-table-checkbox-${id}`;
        return (
            <TableRow
                hover
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                selected={isItemSelected}
                sx={{ cursor: 'pointer' }}
            >
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onClick={(event) => handleClick(event, id)}
                        inputProps={{
                            'aria-labelledby': labelId,
                        }}
                    />
                </TableCell>
                <TableCell>
                    <p>
                        {id}
                    </p>
                </TableCell>
                <TableCell>
                    <Link to={"/admin/users/" + user?.id}>
                        <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center">
                            <Avatar 
                                src={user?.avatar} 
                                sx={{
                                    bgcolor: "#000",
                                }} 
                                children={getInitials(user?.display_name || user?.email)}
                            />
                            <Box>
                                <p style={{ fontWeight: 600 }}>
                                    {user?.display_name || "-"}
                                </p>
                                <p style={{ fontSize: 12}}>
                                    {user?.email}
                                </p>
                            </Box>
                        </Stack>
                    </Link>
                </TableCell>
                <TableCell>
                    <Link to={"/admin/memberships/" + membership?.id}>
                        <p>
                            {membership?.title}
                        </p>
                    </Link>
                </TableCell>
                <TableCell>
                    <p>
                        {start_date ? dayjs(start_date, "YYYY-MM-DD").format("DD/MM/YYYY") : "-"}
                    </p>
                </TableCell>
                <TableCell>
                    <StatusLabel end_date={end_date}/>
                </TableCell>
                <TableCell>
                    <Link to={`/admin/subscriptions/${id}/detail`}>
                        <p className="capitalize underline">
                            {sessions_used} / {max_sessions == "-1" ? "unlimited" : max_sessions}
                        </p>    
                    </Link>
                </TableCell>
                <TableCell>
                    <p className={classNames("capitalize", {
                        "text-yellow-600": max_sessions != "-1" &&  (max_sessions - sessions_used) <= 3,
                        "text-red-600": max_sessions - sessions_used == 0
                    })}>
                        {max_sessions == "-1" ? "unlimited" : (max_sessions - sessions_used)}
                    </p>
                </TableCell>
                <TableCell>
                    <div
                        className={classNames("inline-block text-white px-1 text-sm capitalize", {
                            "bg-red-600": status == 'inactive',
                            "bg-yellow-600": status == 'freeze',
                            "bg-green-600": status == 'active',
                        })}>
                            {status}
                    </div>
                </TableCell>
                <TableCell>
                    <DateCell time={created_at}/>
                </TableCell>
                <TableCell sx={{ width: 100}}>
                    <ActionCell
                        rowData={rowData}
                        editUrl="/admin/subscriptions/"
                        setDeleteItem={setDeleteItem}
                    >
                    <Box sx={{px: 2}}>
                        <LoadingButton 
                            variant='outlined' 
                            loading={sendEmail == id}
                            onClick={() => handleSendExtendEmail(id)} 
                            startIcon={<MailOutlineOutlined fontSize="small" />}>
                            {t("Send an extend email")}
                        </LoadingButton>
                    </Box>
                    </ActionCell>
                </TableCell>
            </TableRow>
        )
    }

    return (
        <Box sx={{ width: '100%' }}>
            <NavHeader
                title={t("Subscriptions")}
                breadcrumb={[
                    {
                        title: t('Subscriptions')
                    }
                ]}
                endAction={(
                    <Link to="/admin/subscriptions/create">
                        <Button
                            startIcon={<AddIcon />}
                            variant='contained'
                            color='primary'>
                            {t("Create subscription")}
                        </Button>
                    </Link>
                )}
            />
            <Box >
                <FilterTable filter={filter} setFilter={setFilter} />
                <FilterSubscription filter={filter} setFilter={setFilter} />
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer sx={{ maxHeight: 640, maxWidth: "calc(100vw - 54px)" }}>
                        <Table
                            stickyHeader aria-label="sticky table"
                            size='medium'>
                            <EnhancedTableHead
                                numSelected={selected.length}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={data.length}
                            />
                            <TableBody>
                                {data.map((row) => {
                                    return (
                                        <Row rowData={row} key={row.id} />
                                    );
                                })}
                            </TableBody>
                        </Table>
                        {selected.length ? (
                            <EnhancedTableToolbar
                                numSelected={selected.length}
                                isLoading={deleteLoading}
                                onSubmit={() => handleDeleteMultipeData(selected)} />
                        ) : ""}
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[12, 24, 36]}
                        component="div"
                        count={total || 0}
                        rowsPerPage={parseInt(filter?.per_page) || 12}
                        page={parseInt(filter?.paged - 1)}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Box>
            <ConfirmDelete
                open={deleteItem ? true : false}
                setOpen={setDeleteItem}
                isLoading={deleteLoading}
                onSubmit={() => handleDeleteData(deleteItem)}
            />
        </Box>
    );
}