import { Box, IconButton, Grid, Table, TableCell, TableBody, TableHead, TableRow, Switch, FormControlLabel, Button, Select, MenuItem, Stack, TableContainer, Divider, Chip, Typography } from '@mui/material'
import { useEffect, useState, useRef, Fragment } from 'react'
import NavHeader from '@/components/NavHeader'
import { updateSession, findOneSession } from '@/utils/Sessions'
import { useSelector } from 'react-redux'
import { useToasterContext } from '@/utils/ToasterContext'

import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';

import { styled } from '@mui/material/styles';
import { findManyUsers } from '@/utils/Users'
import { findManyClasses } from '@/utils/Classes'
import { LoadingButton } from '@mui/lab'
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import validate from 'validate.js'
import PostPicker from '@/components/PostPicker'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import dayjs from 'dayjs'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import PageLoading from '@/components/PageLoading'
import customParseFormat from 'dayjs/plugin/customParseFormat';
import NotifySession from './Components/NotifySession'
import Info from '@/components/Info'
import classNames from 'classnames'
import BookForSession from './BookForSession'
import { useTranslation } from 'react-i18next'

dayjs.extend(customParseFormat);

const CreateSession = () => {

    const { t } = useTranslation();
    const { token } = useSelector((state) => state.auth);
    const { id } = useParams();
    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const [isLoading, setIsLoading] = useState(false);
    const [formError, setFormError] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [defaultValue, setDefaultValue] = useState(false);
    const [pageInit, setPageInit] = useState(false);
    const [trainer, setTrainer] = useState(false);
    const [AcademicClass, setClass] = useState([]);
    const [users, setUsers] = useState([]);
    const [notify, setNotify] = useState(false);
    const [classTypes, setClassTypes] = useState([]);
    const [trainerOptions, setTrainerOptions] = useState([]);
    const [selectedTrainer, setSelectedTrainer] = useState(false);
    const [step, setStep] = useState(0);
    const [openAnotherBook, setOpenAnotherBook] = useState(false);
    const [notifyId, setNotifyId] = useState(false);
    const [selectedClassType, setSelectedClassType] = useState(false);

    const formRef = useRef();
    
    const navigate = useNavigate();

    useEffect(() => {
        const step = searchParams.get("step");
        if(step){
            setStep(step);
        }
    }, [])

    useEffect(() => {
        const class_types = AcademicClass?.class_types;
        let classTypeInfo = class_types ? JSON.parse(class_types) : null;
        if(Array.isArray(classTypeInfo) && classTypeInfo.length && defaultValue?.class_types){
            let selectedClass = classTypeInfo.find((val) => val?.title == defaultValue?.class_types);
            setSelectedClassType(selectedClass);
        }
    }, [defaultValue, AcademicClass]);

    async function handleFindOne() {
        try {
            const response = await findOneSession(token, id);
            if (response && !response.error) {
                const { data } = response;
                if (data) {
                    const {
                        capacity,
                        trainer,
                        start_time,
                        end_time,
                        users,
                        is_private,
                        is_free_session,
                        class_types,
                        trainer_id
                    } = data;
                    const academicClass = data?.class;
                    if(Array.isArray(academicClass?.trainers) && academicClass?.trainers?.length){
                        setTrainerOptions(academicClass?.trainers || []);
                        setSelectedTrainer(trainer_id);
                    }
                    setClassTypes(JSON.parse(academicClass?.class_types) || []);
                    setClass(academicClass);
                    setTrainer(trainer);
                    setUsers(users);
                    setDefaultValue(data);
                }
            }
        } catch (error) {
            console.error('error', error);
        } finally {
            setPageInit(true)
        }
    }

    async function handleUpdate(token, id, data) {
        try {
            setIsLoading(true);
            startProgress();
            const response = await updateSession(token, id, data);
            const sent_notify = data?.sent_notify;
            if (response && !response.error) {
                const { data } = response;
                if(data){
                    showMessage('success', t('Data updated successfully!'));
                    if(sent_notify){
                        showMessage('success', t('The email has been sent successfully to all users and trainers!'));
                    }
                } else {
                    showMessage('error', t('An error occurred, please try again later!'));
                }
            } else {
                showMessage('error', t('An error occurred, please try again later!'));
            }
        } catch (error) {
            showMessage('error', t('An error occurred, please try again later!'));
        } finally {
            setIsLoading(false);
            completeProgress(true);
        }
    }

    function handleChangeClass(value){
        const { trainers, class_types } = value;
        setClassTypes(JSON.parse(class_types) || []);
        setTrainerOptions(trainers);
        setClass(value)
    }

    async function handleSentNotify (id){
        try {
            console.log('id', id)
        } catch (error) {
            
        }
    }


    function handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const user_ids = users.map((val) => val.id);
        const schedule_date = dayjs(data.get('schedule'), "DD/MM/YYYY").format('YYYY-MM-DD');
        
        const start_time = dayjs(data.get('start_time'), 'HH:mm').format("HH:mm:ss");
        const end_time = dayjs(data.get('end_time'), 'HH:mm').format("HH:mm:ss");
        const duration_minutes  = dayjs(data.get('end_time'), 'hh:mm').diff(dayjs(data.get('start_time'), 'hh:mm'), 'minute');

        const notify = data.get('sent_notify');
        const is_free_session = data.get('is_free_session');
        const capacity = data.get('capacity');
        const is_private = data.get('is_private');
        const class_types = data.get('class_types');
        const schedule = data.get('schedule');
        
        const validateForm = {
            trainer_id: selectedTrainer,
            academic_class_id: AcademicClass?.id,
            capacity: capacity,
            schedule: schedule_date,
            start_time: start_time,
            end_time: end_time,
        };

        const dataForm = {
            trainer_id: selectedTrainer,
            academic_class_id: AcademicClass?.id,
            capacity: capacity,
            schedule: schedule_date,
            start_time: start_time,
            end_time: end_time,
            user_ids: user_ids,
            class_types: class_types,
            duration_minutes: duration_minutes,
            is_private: is_private == 'on' ? 1 : 0,
            sent_notify: notify == 'on' ? 1 : 0,
            is_free_session: is_free_session == 'on' ? 1 : 0
        }

        try {
            const error = validate(validateForm, constraints);
            if (!error) {
                handleUpdate(token, id, dataForm);
            } else {
                showMessage("error", t("One or more input fields are invalid!"))
            }
            setFormError(error);
        } catch (error) {

        }
    }

    function handleChangeClassType(e) {
        const form = formRef.current;
        const selectedClassType = classTypes.find((val) => val.title == e.target.value);
        form.elements['capacity'].value = selectedClassType?.capacity;
    }

    // Custom validator to ensure end_time is after start_time
    validate.validators.timeAfter = function (value, options, key, attributes) {
        const startTime = parseTime(attributes.start_time);
        const endTime = parseTime(value);
        if (startTime && endTime && endTime <= startTime) {
            return options.message || t("must be after start time");
        }
    };

    // Helper function to parse time in hh:mm AM/PM format to Date object
    function parseTime(timeString) {
        const [time, modifier] = timeString.split(' ');
        let [hours, minutes] = time.split(':').map(Number);

        if (modifier === 'PM' && hours < 12) {
            hours += 12;
        }
        if (modifier === 'AM' && hours === 12) {
            hours = 0;
        }

        const date = new Date();
        date.setHours(hours, minutes, 0, 0);
        return date;
    }


    useEffect(() => {
        if(AcademicClass){
            const { is_private, class_types, capacity } = AcademicClass;
            setClassTypes(class_types ? JSON.parse(class_types) : []);
            setDefaultValue((defaultValue) => ({
                ...defaultValue,
                is_private: is_private,
                capacity: capacity}))
        }
    }, [AcademicClass])

    useEffect(() => {
        if(token){
            handleFindOne();
        }
    }, [token])

    const constraints = {
        // trainer_id: {
        //     presence: { allowEmpty: false, message: "is required" }
        // },
        // academic_class_id: {
        //     presence: { allowEmpty: false, message: "is required" }
        // },
        // schedule: {
        //     presence: { allowEmpty: false, message: "is required" }
        // },
        // capacity: {
        //     presence: { allowEmpty: false, message: "is required" }
        // },
        // start_time: {
        //     presence: { allowEmpty: false, message: "is required" },
        //     format: {
        //         pattern: /^(0?[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/,
        //         message: "must be in the format hh:mm AM/PM"
        //     }
        // },
        // end_time: {
        //     presence: { allowEmpty: false, message: "is required" },
        //     format: {
        //         pattern: /^(0?[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/,
        //         message: "must be in the format hh:mm AM/PM"
        //     },
        //     timeAfter: {
        //         message: "must be after start time"
        //     }
        // }
    };

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    if (!pageInit) {
        return (
            <PageLoading />
        );
    }

    const start = dayjs(defaultValue.start_time);
    const end = dayjs(defaultValue.end_time);
    // Tính khoảng thời gian giữa hai thời điểm trong phút
    const durationInMinutes = end.diff(start, 'minute');

    const currentDayString = dayjs().format('MM/DD');
    const currentDay = dayjs(currentDayString, 'MM/DD');

    const sessionDateCompare = currentDay.isAfter(start);

    return (
        <Fragment>
            <Box sx={{ width: '100%' }}>
                <NavHeader
                    title={t("Update Session")}
                    breadcrumb={[
                        {
                            title: t('Sessions'),
                            slug: '/receptionist/sessions'
                        },
                        {
                            title: t('Update Session')
                        }
                    ]}
                    endAction={
                       <Stack direction="row" spacing={1}>
                        <Button 
                            variant='contained'
                            size="small"
                            onClick={() => navigate(`/receptionist/sessions/${id}/checkin`)}>
                            {t("Start to checkin")}
                        </Button>
                       </Stack>
                    }
                    backLink={"/receptionist/sessions?step=" + step}
                />
                       <div className="max-w-[640px] mx-auto">
                    <Box>
                            <Divider sx={{ mb: 2 }}>
                                <Chip label={t("Session information")} className='uppercase' sx={{borderRadius: 0}}/>
                            </Divider>
                            <Box sx={{ mb: 3 }}>
                                {
                                    selectedClassType ? (
                                        <Fragment>
                                            <Typography>
                                                <span className='text-2xl font-bold uppercase'>{selectedClassType?.title}</span>
                                            </Typography>
                                            <Typography className="text-sm" sx={{mb: 3}}>
                                                {t(selectedClassType?.description)}
                                            </Typography>
                                        </Fragment>
                                    ) : (
                                        AcademicClass ?
                                            <Fragment>
                                                <Typography>
                                                    <span className='text-2xl font-bold upe'>{AcademicClass?.title}</span>
                                                </Typography>
                                                <Typography className="text-sm" sx={{mb: 3}}>
                                                    {AcademicClass?.description}
                                                </Typography>
                                            </Fragment>
                                            : ""
                                    )
                                }
                                {
                                    start ?
                                        <Fragment>
                                            <Typography>
                                                {t("on")} <span className='font-bold uppercase'> {start.format('ddd, DD/MM')}</span>
                                            </Typography>
                                            <Typography>
                                                {t("at")} <span className='font-bold uppercase'>{start.format('HH:mm')}</span>
                                            </Typography>
                                        </Fragment>
                                        : ""
                                }
                                {
                                    durationInMinutes ?
                                        <Typography>
                                            {t("Duration")}: <span className="font-bold">{durationInMinutes} {t("minutes")}</span>
                                        </Typography>
                                        : ""
                                }
                                <Typography>
                                    {t("Instructor")}: <strong>{trainer?.full_name || "-"}</strong> 
                                </Typography>

                                {    defaultValue?.is_free_session ? (
                                        <Typography sx={{ mb: 2 }}>
                                            {t("Free to join")} <Info title={t("You can register for this session without having to sign up for any membership plans")}/>
                                        </Typography>
                                    ) : ""
                                }
                                
                            </Box>
                        </Box>

                        <Paper sx={{ width: '100%', overflow: 'hidden', mb: 3, borderRadius: 0 }}>
                            <TableContainer sx={{ maxHeight: 640, maxWidth: "calc(100vw - 54px)" }}>
                                <Table
                                    stickyHeader aria-label="sticky table"
                                    size='medium'>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ width: 60 }}>{t("No")}</TableCell>
                                                <TableCell>{t("Users / Shared users")}</TableCell>
                                                <TableCell>{t("Status")}</TableCell>
                                                <TableCell>{t("Mailed")}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Array.isArray(users) && users.length ?
                                                users.map((row, index) => {
                                                    const { pivot } = row;
                                                    console.log('pivot', pivot);
                                                    return (
                                                        <StyledTableRow
                                                            key={row.id}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                            <TableCell component="th" scope="row">
                                                                {(index + 1)}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {pivot?.is_shared ? pivot?.name_shared : row.display_name || row?.email}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {
                                                                    pivot?.status ? (
                                                                        <span className={classNames("border px-2 capitalize text-sm", {
                                                                            'border-green-200 bg-green-100 text-green-600': pivot.status == 'active',
                                                                            'border-red-200 bg-red-100 text-red-600': pivot.status == 'canceled',
                                                                            'border-yellow-200 bg-yellow-100 text-yellow-600': pivot.status == 'pending'
                                                                        })}>
                                                                            {{
                                                                                active: t("Booked"),
                                                                                pending: t("Pending"),
                                                                                canceled: t("canceled"),
                                                                            }[pivot.status]}
                                                                        </span>
                                                                    ) : "-"
                                                                }
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {
                                                                    !pivot?.sent_mail ? (
                                                                        <LoadingButton onClick={() => handleSentNotify(row.id)} >
                                                                            Sent confirm email
                                                                        </LoadingButton>
                                                                    ) : t("Sent")
                                                                }
                                                            </TableCell>
                                                        </StyledTableRow>
                                                    )
                                                }) : ""}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        
            </div>
        </Box>
        <NotifySession open={notify} setOpen={setNotify} />
    </Fragment>
    )
}

export default CreateSession
