import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs';
import { Box, Button, Typography } from '@mui/material';

import EditSchedule from './EditSchedule';
import { findManyTrainerSchedules } from '@/utils/TrainerSchedule';
import { useSelector } from 'react-redux';
import NavHeader from '@/components/NavHeader';
import { useParams } from 'react-router-dom';
import { updateTrainer, findOneTrainer } from '@/utils/Trainers'
import { useTranslation } from 'react-i18next';

const index = () => {

    const [calendar, setCalendar] = useState(false);
    const [step, setStep] = useState(0);
    const [edit, setEdit] = useState(false);
    const [data, setData] = useState([]);

    const { user, token } = useSelector((state) => state.auth);
    const { id } = useParams();
    const [pageInit, setPageInit] = useState(false);
    const [trainer, setTrainer] = useState(false);

    const { t } = useTranslation();

    async function handleFindOne() {
        try {
            console.log('1');
            const response = await findOneTrainer(token, id);
            if (response && !response.error) {
                const { data } = response;
                if (data) {
                    setTrainer(data);
                }
            }
        } catch (error) {
            console.error("Error: ", error);
        } finally {

        }
    }

    async function handleFindData() {
        try {
            console.log('2');
            const monthYear = dayjs().add(step, 'month').format("YYYY-MM")
            const filter = {
                month: monthYear
            };
            const response = await findManyTrainerSchedules(id, filter);
            if (response && !response.error) {
                setData(response.data);
            } else {
                setData([])
            }
        } catch (error) {

        } finally {
            setPageInit(true);
        }
    }

    useEffect(() => {
        if(token) {
            handleFindOne();
            handleFindData();
        }
    }, [token])

    function createMonthCalendar(month) {
        const startOfMonth = month.startOf('month');
        const endOfMonth = month.endOf('month');
        const daysInMonth = endOfMonth.date();
        const today = dayjs();

        let calendar = [];
        let currentDay = startOfMonth;

        // Thêm các ngày của tháng trước
        const startDayOfWeek = startOfMonth.day(); // 0: Chủ nhật, 1: Thứ 2, ..., 6: Thứ 7
        if (startDayOfWeek !== 0) { // Nếu ngày bắt đầu không phải Chủ nhật
            for (let i = startDayOfWeek - 1; i >= 1; i--) {
                const dayFromPrevMonth = startOfMonth.subtract(i + 1, 'day');
                calendar.push({
                    day: dayFromPrevMonth.date(),
                    fullDate: dayFromPrevMonth.format('YYYY-MM-DD'),
                    dayOfWeek: dayFromPrevMonth.format('ddd'),
                    isCurrentMonth: false,
                    active: dayFromPrevMonth.isSame(today, 'day'),
                    disabled: dayFromPrevMonth.isBefore(today, 'day'),
                });
            }
        }

        // Lặp qua từng ngày trong tháng
        while (currentDay.isSameOrBefore(endOfMonth)) {
            calendar.push({
                day: currentDay.date(),
                fullDate: currentDay.format('YYYY-MM-DD'),
                dayOfWeek: currentDay.format('ddd'),
                isCurrentMonth: true,
                active: currentDay.isSame(today, 'day'),
                disabled: currentDay.isBefore(today, 'day'),
            });
            currentDay = currentDay.add(1, 'day');
        }

        // Thêm các ngày của tháng sau nếu mảng chưa đủ 35 ngày
        const totalDays = calendar.length;
        const daysToAdd = 35 - totalDays;
        for (let i = 0; i < daysToAdd; i++) {
            const dayFromNextMonth = endOfMonth.add(i + 1, 'day');
            calendar.push({
                day: dayFromNextMonth.date(),
                fullDate: dayFromNextMonth.format('YYYY-MM-DD'),
                dayOfWeek: dayFromNextMonth.format('ddd'),
                isCurrentMonth: false,
                active: dayFromNextMonth.isSame(today, 'day'),
                disabled: dayFromNextMonth.isBefore(today, 'day'),
            });
        }

        return calendar;
    }


    useEffect(() => {
        const currentMonth = dayjs().startOf('month').add(step, 'month');
        const calendar = createMonthCalendar(currentMonth);
        setCalendar(calendar);
        if(pageInit){
            handleFindData()
        }
    }, [step])

    const currentDay = dayjs();

    return (
        <Box>
            <NavHeader
                title="Checkout"
                breadcrumb={[
                    {
                        title: 'Checkout'
                    }
                ]}
                backLink={ "/admin/trainers/" + id }
            />
            <Box sx={{ p: 3 }}>
                <Box className='flex justify-between gap-3 mb-5'>
                    <Button
                        onClick={() => setStep((step) => step - 1)}
                        variant='contained'>
                        Prev
                    </Button>
                    <Typography className='font-bold uppercase'>
                        {dayjs().add(step, 'month').format("MMMM, YYYY")}
                    </Typography>
                    <Button onClick={() => setStep((step) => step + 1)}
                        type='plain'
                        textColor='#333'
                        variant='contained'>
                        Next
                    </Button>
                </Box>
                <div className='grid grid-cols-7 gap-[5px]'>
                    {
                        Array.isArray(calendar) && calendar.length ? calendar.map((val, index) => {
                            const isActive = currentDay.startOf("day").isBefore(dayjs(val.fullDate, "YYYY-MM-DD"));
                            const { isCurrentMonth, day, dayOfWeek, fullDate } = val;
                            let avaiableDay = {};
                            if (Array.isArray(calendar) && calendar.length) {
                                avaiableDay = data.find((_v) => _v.schedule == val.fullDate);
                            }
                            return (
                                <Box
                                    key={index}
                                    sx={{
                                        borderWidth: 1,
                                        padding: "8px",
                                        borderColor: "#EEF2F6",
                                        backgroundColor: avaiableDay?.is_off ? "#FFAB91" : !isCurrentMonth ? "#e8e8e8" : avaiableDay ? "#fff" : "#EEEEEE",
                                    }}>
                                    <div
                                        className="cursor-pointer"
                                        onClick={() => setEdit(fullDate)}>
                                        <p className='font-bold'>
                                            {day} {avaiableDay?.is_off ? " / Off" : ""}
                                        </p>
                                        <p className='uppercase'>
                                            {t(dayOfWeek)}
                                        </p>
                                        <p className='uppercase text-[12px]'>
                                            {avaiableDay ? t("Edited") : "By default"}
                                        </p>
                                    </div>
                                </Box>
                            )
                        }) : ""
                    }
                </div>
            </Box>
            <EditSchedule
                date={edit}
                setDate={setEdit}
                defaultSchedule={trainer?.schedule}
                reload={() => handleFindData()}
            />
        </Box>
    )
}

export default index
