import { useState, useEffect } from 'react';
import { alpha } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import Avatar from '@mui/material/Avatar';
import AddIcon from '@mui/icons-material/Add';
import { deleteSession, findManySessions } from '@/utils/Sessions';

import ConfirmDelete from '@/components/ConfirmDelete';
import NavHeader from '@/components/NavHeader';
import { Select, Stack, MenuItem, FormControl, FormLabel, InputLabel } from '@mui/material';

import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekday from 'dayjs/plugin/weekday';
import dayjs from 'dayjs';
import ActionCell from '@/components/ActionCell';
import { useToasterContext } from '@/utils/ToasterContext';
import { useTranslation } from 'react-i18next';

const headCells = [
    {
        id: 'class',
        numeric: false,
        disablePadding: true,
        label: 'Class',
    },
    {
        id: 'users',
        disablePadding: false,
        label: 'Trainer',
    },
    {
        id: 'capacity',
        disablePadding: false,
        label: 'Capacity',
    },
    {
        id: 'start_time',
        disablePadding: false,
        label: 'Start in',
    },
    {
        id: 'duration',
        disablePadding: false,
        label: 'Duration',
    },
    {
        id: 'action',
        disablePadding: false,
        label: 'Action',
    },
];

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const { t } = useTranslation();
    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <Typography sx={{ fontWeight: 600 }}>
                            {t(headCell.label)}
                        </Typography>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}


function EnhancedTableToolbar(props) {
    const { numSelected } = props;
    const { t } = useTranslation();
    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <>
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {numSelected} {t("selected")}
                    </Typography>
                    <Tooltip title={t("Delete")}>
                        <IconButton>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </>
            ) : ""
            }
        </Toolbar>
    );
}

const DayView = ({ reload, data }) => {

    const { t } = useTranslation();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [deleteItem, setDeleteItem] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const { showMessage } = useToasterContext()

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = data.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    async function handleDeleteData(id) {
        try {
            setDeleteLoading(true);
            const response = await deleteSession(token, id);
            if (response && !response.error) {
                const { id } = response;
                if (id) {
                    showMessage("success", t("Delete item successully!"));
                    reload();
                }
            } else {
                showMessage("error", t("An error occurred during delete item!"))
            }
        } catch (error) {
            showMessage("error", t("An error occurred during delete item!"))
        } finally {
            setDeleteLoading(false);
            setDeleteItem(false);
        }
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

    return (
        <Paper sx={{ width: '100%', mb: 2 }}>
            {
                selected.length ? <EnhancedTableToolbar numSelected={selected.length} /> : ""
            }
            <TableContainer>
                <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size='medium'
                >
                    <EnhancedTableHead
                        numSelected={selected.length}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={data.length}
                    />
                    <TableBody>
                        {data.sort((a, b) => dayjs(a.start_time).unix() - dayjs(b.start_time).unix()).map((row, index) => {
                            const isItemSelected = isSelected(row.id);
                            const labelId = `enhanced-table-checkbox-${index}`;
                            const { id, trainer, users, start_time, end_time, duration_minutes, sent_email, is_private, capacity, class_types } = row;
                            const classInfo = row.class;
                            const start = dayjs(start_time);
                            const end = dayjs(end_time);
                            const isActive = dayjs().isBefore(start);
                            const isInBetween = dayjs().isBetween(start, end);
                            const activeUser = Array.isArray(users) && users?.length ? users.filter((val) => val?.pivot?.status == "active"): [];
                            const candeledUser = Array.isArray(users) && users?.length ? users.filter((val) => val?.pivot?.status == "canceled"): []
                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row?.id}
                                    selected={isItemSelected}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            color="primary"
                                            checked={isItemSelected}
                                            onClick={(event) => handleClick(event, row?.id)}
                                            inputProps={{
                                                'aria-labelledby': labelId,
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Link className='' to={"/receptionist/sessions/" + row?.id}>
                                            <strong className="uppercase">{row?.class_types ? row.class_types : row?.class?.title}</strong>
                                            <div className="flex gap-1 right-5 top-1">
                                                { isInBetween ? (<span className='border border border-black px-1 text-xs uppercase !text-[8px]'>{t("Ongoing")}</span>) : !isActive ? <span className='border border border-black px-1 text-xs uppercase !text-[8px]'>{t("Passed")}</span> : ""}
                                                {
                                                    is_private ? <span className='border border border-black px-1 text-xs uppercase !text-[8px]'>
                                                        {t("Private")}
                                                    </span> : ""
                                                }
                                                {
                                                    sent_email ? <span className='border bg-green-50 border-green-200 text-green-600 px-1 text-xs uppercase !text-[8px]'>
                                                        {t("Email")}
                                                    </span> : ""
                                                }
                                            </div>
                                        </Link>
                                    </TableCell>
                                    <TableCell>
                                        {row?.trainer?.full_name}
                                    </TableCell>
                                    <TableCell>{row?.users?.length + "/" + row?.capacity}</TableCell>
                                    <TableCell>{start.format("HH:mm, DD/MM/YYYY")}</TableCell>
                                    <TableCell>{duration_minutes} {t("minutes")}</TableCell>
                                    <TableCell sx={{ width: 120}}>
                                        <Link to={"/receptionist/sessions/" + row.id}>
                                            <Button variant='contained'>
                                                {t("View")}
                                            </Button>
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[12, 24, 36]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <ConfirmDelete
                open={deleteItem ? true : false}
                setOpen={setDeleteItem}
                isLoading={deleteLoading}
                onSubmit={() => handleDeleteData(deleteItem)}
            />
        </Paper>
    )
}

export default DayView
