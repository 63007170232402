import { useState, useEffect } from 'react';
import { alpha } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ConfirmDelete from '@/components/ConfirmDelete';
import NavHeader from '@/components/NavHeader';
import ActionCell from '@/components/ActionCell';
import FilterTable from '@/components/FilterTable';
import PageLoading from '@/components/PageLoading';
import dayjs from 'dayjs';

import { useToasterContext } from '@/utils/ToasterContext';
import { useSelector } from 'react-redux';
import { deleteSubscription, deleteSubscriptions, findManySubscriptions } from '@/utils/Subscriptions';
import { Avatar, Chip, Stack } from '@mui/material';
import classNames from 'classnames';
import { getInitials } from '@/utils/Hooks';

const headCells = [
    {
        id: 'membership',
        disablePadding: false,
        label: 'Membership',
    },
    {
        id: 'start_date',
        disablePadding: false,
        label: 'Start date',
    },
    {
        id: 'end_date',
        disablePadding: false,
        label: 'End date',
    },
    {
        id: 'max_sessions',
        disablePadding: false,
        label: 'Max sessions',
    },
    {
        id: 'sessions_used',
        disablePadding: false,
        label: 'Sessions used',
    },
    {
        id: 'status',
        disablePadding: false,
        label: 'Status',
    }
];

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount } = props;
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <Typography sx={{ fontWeight: 600 }}>
                            {headCell.label}
                        </Typography>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}


function EnhancedTableToolbar(props) {
    const { numSelected, onSubmit } = props;
    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <>
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {numSelected} selected
                    </Typography>
                    <Tooltip title="Delete">
                        <IconButton onClick={onSubmit}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </>
            ) : ""
            }
        </Toolbar>
    );
}

export default function EnhancedTable({ defaultData }) {

    const [data, setData] = useState(defaultData);

    const Row = ({ rowData }) => {
        const { id, user, membership, status, max_sessions, sessions_used, start_date, end_date } = rowData

        return (
            <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                sx={{ cursor: 'pointer', mb: 3 }}
            >
                <TableCell>
                    <Link to={"/admin/memberships/" + membership?.id}>
                        <Typography sx={{ fontWeight: 600 }}>
                            {membership?.title}
                        </Typography>
                    </Link>
                </TableCell>
                <TableCell>
                    <Typography>
                        {start_date ? dayjs(start_date, "YYYY-MM-DD").format("DD/MM/YYYY") : "-"}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography>
                        {end_date ? dayjs(end_date, "YYYY-MM-DD").format("DD/MM/YYYY") : "-"}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography>
                        {max_sessions == "-1" ? "unlimited" : max_sessions}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography>
                        {sessions_used}
                    </Typography>
                </TableCell>
                <TableCell>
                    <div
                        className={classNames("inline-block text-white px-1 text-sm capitalize", {
                            "bg-red-600": status == 'inactive',
                            "bg-yellow-600": status == 'freeze',
                            "bg-green-600": status == 'active',
                        })}>
                        {status}
                    </div>
                </TableCell>
            </TableRow>
        )
    }

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden', mb: 3, borderRadius: 0 }}>
            <TableContainer sx={{ maxHeight: 640, maxWidth: "calc(100vw - 54px)" }}>
                <Table
                    stickyHeader aria-label="sticky table"
                    size='medium'>
                    <EnhancedTableHead /> 
                    <TableBody>
                        {data.map((row, index) => {
                            return (
                                <Row rowData={row} key={row.id} />
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}